import React, {Component} from "react";
import {Container} from "react-bootstrap";
import $ from "jquery";
import circle from "../Assets/statictics-circle.png";
import Pentagon from "../Assets/plan-pentagon.png";
import Square from "../Assets/plan-square.png";

class PlansMobile extends Component {
  state = {};

  selectedPointer = "";
  selectedPlan = "empty";

  choosePlan(plan) {
    if (plan !== this.selectedPlan) {
      $(`#${this.selectedPlan}`).removeClass("plan-container-selected");
      $(`#${this.selectedPlan}`).css({height: "24rem"});
      $(`#${plan}`).addClass("plan-container-selected");
      $(`#${plan}`).css({height: "25rem"});
      this.selectedPlan = plan;
    }
  }

  componentDidMount() {
    this.choosePlan("gold");
    this.selectPointer("mid-plan-pointer");
  }

  handleScroll() {
    let widthScroll = $("#carousel-plan-container").scrollLeft();
    $("#left-plan-pointer").removeClass("coro-pointer-selected");
    $("#mid-plan-pointer").removeClass("coro-pointer-selected");
    $("#right-plan-pointer").removeClass("coro-pointer-selected");

    if (widthScroll < 250) {
      $("#left-plan-pointer").addClass("coro-pointer-selected");
    } else if (widthScroll >= 250 && widthScroll < 500) {
      $("#mid-plan-pointer").addClass("coro-pointer-selected");
    } else if (widthScroll > 500) {
      $("#right-plan-pointer").addClass("coro-pointer-selected");
    }
  }

  selectPointer(id) {
    if (id !== this.selectedPointer) {
      // if (this.selectedPointer)
      //   $("#" + this.selectedPointer).removeClass("coro-pointer-selected");
      // this.selectedPointer = id;
      // $("#" + this.selectedPointer).addClass("coro-pointer-selected");

      switch (id) {
        case "left-plan-pointer":
          $("#carousel-plan-container").animate({scrollLeft: "0%"}, 500);
          break;
        case "mid-plan-pointer":
          $("#carousel-plan-container").animate({scrollLeft: "285%"}, 500);
          break;
        case "right-plan-pointer":
          $("#carousel-plan-container").animate({scrollLeft: "600%"}, 500);
          break;
        default:
          break;
      }
    }
  }

  render() {
    return (
      <div className="text-center py-4">
        <div
          onScroll={() => this.handleScroll()}
          id="carousel-plan-container"
          style={{
            minWidth: "100%",
            overflowX: "scroll",
            marginTop: "2rem",
            padding: "0 13vw"
          }}>
          <div id="carousel-scrollbar" style={{display: "inline-flex"}} class>
            <Container
              className="bg-color-cream text-left color-bright-pink plan-container-mobile mr-3"
              style={{
                margin: "auto 0",
                fontSize: "0.75rem"
              }}
              id="basic"
              onClick={() => this.choosePlan("basic")}>
              <b>SILVER</b>
              <Container
                className="text-center my-3"
                style={{width: "8rem", height: "8em"}}>
                <img className="subscription-icon" src={circle} alt="" />
              </Container>
              <Container className="text-center mt-2 mb-4 p-0">
                <h5 className="p-0">
                  <b>INR 3000</b> / 3 Months
                </h5>
              </Container>

              <Container className="px-0 my-2">
                <b>Lorem ipsum</b>
              </Container>
              <Container className="px-0 my-2">
                <b>Dolor sit amet</b>
              </Container>
              <Container className=" my-2 px-0">
                <b>Consectetur adipisicin</b>
              </Container>
              <Container className="px-0">
                <p>Lorem ipsum</p>
              </Container>
              <Container className="px-0">
                <p>Lorem ipsum</p>
              </Container>
            </Container>

            <Container
              className="bg-color-cream text-left p-4 color-bright-pink plan-container-mobile mr-3"
              style={{
                margin: "auto 0",
                fontSize: "0.75rem"
              }}
              id="gold"
              onClick={() => this.choosePlan("gold")}>
              <b>Gold</b>
              <Container
                className="text-center my-3"
                style={{width: "8rem", height: "8em"}}>
                <img className="subscription-icon" src={Pentagon} alt="" />
              </Container>
              <Container className="text-center mt-2 mb-4 p-0">
                <h5 className="p-0">
                  <b>INR 6000</b> / 6 Months
                </h5>
              </Container>

              <Container className="px-0 my-2">
                <b>Lorem ipsum</b>
              </Container>
              <Container className="px-0 my-2">
                <b>Dolor sit amet</b>
              </Container>
              <Container className=" my-2 px-0">
                <b>Consectetur adipisicin</b>
              </Container>
              <Container className="px-0">
                <p>Lorem ipsum</p>
              </Container>
              <Container className="px-0">
                <p>Lorem ipsum</p>
              </Container>
            </Container>

            <Container
              className="bg-color-cream text-left p-4 color-bright-pink plan-container-mobile mr-3"
              id="platinum"
              onClick={() => this.choosePlan("platinum")}>
              <b>Platinum</b>
              <Container
                className="text-center my-3"
                style={{width: "8rem", height: "8em"}}>
                <img className="subscription-icon" src={Square} alt="" />
              </Container>
              <Container className="text-center mt-2 mb-4 p-0">
                <h5 className="p-0">
                  <b>INR 10000</b> / 12 Months
                </h5>
              </Container>

              <Container className="px-0 my-2">
                <b>Lorem ipsum</b>
              </Container>
              <Container className="px-0 my-2">
                <b>Dolor sit amet</b>
              </Container>
              <Container className=" my-2 px-0">
                <b>Consectetur adipisicin</b>
              </Container>
              <Container className="px-0">
                <p>Lorem ipsum</p>
              </Container>
              <Container className="px-0">
                <p>Lorem ipsum</p>
              </Container>
            </Container>
          </div>
        </div>

        <div className="d-inline-flex mt-4">
          <div
            id="left-plan-pointer"
            className="coro-pointer mr-2"
            onClick={() => this.selectPointer("left-plan-pointer")}></div>
          <div
            id="mid-plan-pointer"
            className="coro-pointer mr-2"
            onClick={() => this.selectPointer("mid-plan-pointer")}></div>
          <div
            id="right-plan-pointer"
            className="coro-pointer"
            onClick={() => this.selectPointer("right-plan-pointer")}></div>
        </div>
      </div>
    );
  }
}

export default PlansMobile;
