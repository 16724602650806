export const BOOL_FALSE = false;
export const BOOL_TRUE = true;
export const NULL_STRING = "";
export const GROOM = "Groom";
export const BRIDE = "Bride";
export const MALE = "Male";
export const FEMALE = "Female";
export const SELF = "Self";

export const RELIGIONS = ["Hindu", "Muslim", "Sikh", "Christian"];
export const MOTHER_TONGUE = [
  "Hindi",
  "English",
  "Tamil",
  "Telugu",
  "Urdu",
  "Bengali",
  "Marathi",
  "Gujarati",
  "Kannada",
  "Odia",
  "Malayalam",
  "Punjabi",
  "Assamese",
  "Maithili",
  "Sanskrit"
];
export const COUNTRIES = ["India", "USA", "UAE", "Nepal", "Canada"];
export const STATES = {
  India: ["Delhi", "Maharashtra", "Tamil Nadu", "Uttar Pradesh"],
  USA: ["California", "PA"]
};

export const CITIES = {
  Delhi: ["Delhi", "New Delhi"],
  Maharashtra: ["Mumbai", "Pune"],
  "Uttar Pradesh": ["Noida", "Varanasi"],
  "TAMIL Nadu": ["Chennai", "Bengaluru"]
};

export const collectionName = {
  CONSUMER: "consumer",
  RELATIVES: "relatives"
};

export const HTML_CONTROLS = {
  CHECKBOX: "checkbox"
};

export const RELATIVES = [
  "Father",
  "Mother",
  "Sister",
  "Brother",
  "Uncle",
  "Aunty",
  "Grand Mother",
  "Grand Father",
  "Friend",
  "Cousin Brother",
  "Cousin Sister"
];

export const YESNO = ["Yes", "No"];

export const MARITAL_STATUS = [
  "Single",
  "Divorced",
  "Widowed",
  "Awaiting Divorced",
  "Annulled",
  "Separated"
];

export const GOTRA = [
  "none",
  "Shandilya",
  "Gautama Maharishi",
  "Bharadwaja",
  "Vishvamitra",
  "Jamadagni",
  "Vashista",
  "Kashyapa",
  "Atri",
  "Agastya"
];

export const NAKSHATRA = [
  "none",
  "Kṛttikā (the Pleiades)",
  "Rohinī (Aldebaran)",
  "Mrigashīrsha",
  "Ārdrā (Betelgeuse)",
  "Punarvasu",
  "Pushya",
  "Asleshā",
  "Maghā (Regulus)",
  "Purva phalguni",
  "Uttara phalguni (Denebola)",
  "Hasta",
  "Chitrā (Spica)",
  "Svāti (Arcturus)",
  "Vishākhā",
  "Anurādhā",
  "Jyeshthā",
  "Mūla",
  "Purva ashadha",
  "Uttara ashadha",
  "Shravana",
  "Dhanishta",
  "Satabhishak (Sadachbia)",
  "Purva bhadrapada",
  "Uttara bhadrapada",
  "Revati",
  "Ashvini",
  "Bharani"
];

export const COMMUNITY = {
  Hindu: [
    "Brahmin gotra",
    "Bhat",
    "Banerjee",
    "Trivedi",
    "Chatterjee",
    "Bahun",
    "Dhakal",
    "Gurjar",
    "Dalit",
    "Mukherjee",
    "Mishra",
    "Tamil Brahmin",
    "Other"
  ],
  Muslim: [
    "Al-Ja'fari",
    "Jats of Kutch",
    "Mutwa",
    "Arain (Delhi)",
    "Attarwala",
    "Bargujar",
    "Kathia",
    "Kashmiri Muslim tribes from Hindu lineage",
    "Kashmiri Muslims",
    "Putliwale",
    "Rangrez",
    "Raj Gond",
    "Rajputs",
    "Hingora",
    "Other"
  ]
};

export const APICALLURL = {
  // userAuth: "http://localhost:5001/humble-match/asia-south1/authCheckOnRequest"
  userAuth:
    "https://" +
    process.env.REACT_APP_REGION +
    "-" +
    process.env.REACT_APP_PROJECT_ID +
    ".cloudfunctions.net/authCheckOnRequest"
};
