import React, {useState, useEffect} from "react";
import {Container, Row} from "react-bootstrap";
import TextField from "../../templates/TextInput";
import Dropdown from "../../templates/Dropdown";
import Button from "../../templates/Button";
import ImageSelector from "../../templates/ImageSelector";
import {
  COMMUNITY,
  GOTRA,
  MARITAL_STATUS,
  NAKSHATRA
} from "../../Common/constants";
import {updateUserData} from "../../Model/users";
import {
  RELIGIONS,
  MOTHER_TONGUE,
  COUNTRIES,
  STATES,
  CITIES
} from "../../Common/constants";
import {useSelector} from "react-redux";
import {uploadFileWithName} from "../../Model/uploadFile";
import {getRandomString, getStorageObject} from "../../Common/commonFunction";

export default function CreateProfile(props) {
  const loginReducer = useSelector((state) => state.loginReducer);

  const [images, setImages] = useState({});
  const [fname, setfname] = useState(loginReducer.fname);
  const [lname, setlname] = useState(loginReducer.lname);
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [city, setCity] = useState("");
  const [livingState, setLivingState] = useState("");
  const [religion, setReligion] = useState("");
  const [arrDate, setArrDate] = useState([]);
  const [arrMonth, setArrMonth] = useState([]);
  const [arrYear, setArrYear] = useState([]);
  const [arrState, setArrState] = useState([]);
  const [arrCities, setArrCities] = useState([]);
  const [country, setCountry] = useState("");
  const [motherTongue, setMotherTongue] = useState("");
  const [maritalStatus, setMaritalStatus] = useState(
    loginReducer.maritalStatus
  );
  const [nakshatra, setNakshatra] = useState("");
  const [gothra, setGothra] = useState("");
  const [arrCommunity, setArrCommunity] = useState([]);
  const [community, setCommunity] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const uid = useSelector((state) => state.loginReducer.uid);

  const onSubmit = (e) => {
    setIsSaving(true);
    let profileData = {
      fname,
      lname,
      dob: `${date}-${month}-${year}`,
      religion,
      motherTongue,
      country,
      state: livingState,
      city,
      maritalStatus,
      community,
      gothra,
      nakshatra,
      personalImages: []
    };

    let promise = [];
    [...Array(4)].map((e, date) => {
      let image = images["image" + (date + 1)];
      if (image) {
        promise.push(
          uploadFileWithName(
            getStorageObject(),
            "personalImages",
            loginReducer.uid + "/" + getRandomString() + "_" + image.fileName,
            image.image
          )
        );
      }
      return null;
    });

    Promise.all(promise).then((response) => {
      profileData.personalImages = response;

      updateUserData(profileData, uid)
        .then((res) => {
          props.onSubmit();
        })
        .catch((error) => {
          alert("Something went wrong!");
        })
        .finally(() => {
          setIsSaving(false);
        });
    });
  };

  const onChangeCountry = (value) => {
    setCountry(value);
    setArrState(STATES[value] || []);
    setLivingState("");
    setCity("");
  };

  const onChangeState = (value) => {
    setArrCities(CITIES[value] || []);
    setCity("");
    setLivingState(value);
  };

  useEffect(() => {
    const arrDate = [...Array(31)].map((e, date) => {
      return date + 1;
    });

    setArrDate(arrDate);

    let year = new Date().getFullYear();
    const arrYear = [];
    [...Array(85)].map((e, date) => {
      if (date + 1940 <= year - 18) {
        arrYear.push(date + 1940);
      }
      return null;
    });

    arrYear.sort(function (a, b) {
      return b - a;
    });
    setArrYear(arrYear);

    const arrMonth = [...Array(12)].map((e, date) => {
      return date + 1;
    });
    setArrMonth(arrMonth);
  }, []);

  const handleSelectImage = (e) => {
    const prevState = {...images};
    prevState[e.name] = {
      fileName: e.fileName,
      image: e.value
    };
    setImages(prevState);
  };

  const onChangeReligion = (e) => {
    setReligion(e.target.value);
    setCommunity("");
    setArrCommunity(COMMUNITY[e.target.value] || []);
  };

  return (
    <Container className="w-100 col-lg-12 text-center">
      <Container className="text-center">
        <h1 className="color-pink mt-4 font-weight-normal page-header">
          Welcome to Humble Match!
        </h1>

        <h4 className="color-pink font-weight-normal mt-2 page-sub-header">
          Let’s create the bride/groom’s profile
        </h4>
      </Container>

      <Container className="w-100 col-lg-10 col-sm-12 bg-color-cream">
        <Container className="col-lg-10 col-sm-12 px-0 px-lg-5">
          <Row className="col-sm-12 col-lg-12 m-0 p-0">
            <Container className="col-sm-12 col-lg-6 mt-4 px-lg-3">
              <Container className="justify-content-around d-flex">
                <TextField
                  placeholder="First name"
                  value={fname}
                  onChange={(e) => setfname(e.target.value)}
                />
                <div className="mx-2"></div>
                <TextField
                  placeholder="Last name"
                  value={lname}
                  onChange={(e) => setlname(e.target.value)}
                />
              </Container>

              <Container className="text-left mt-3">
                <label className="label-text">Date of Birth* </label>
              </Container>

              <Container className="justify-content-between d-flex">
                <Dropdown
                  title="Date"
                  style={{width: "30%"}}
                  data={arrDate}
                  name="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
                <Dropdown
                  title="Month"
                  style={{width: "30%"}}
                  data={arrMonth}
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                />
                <Dropdown
                  title="Year"
                  style={{width: "30%"}}
                  data={arrYear}
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                />
              </Container>

              <Container className="mt-3">
                <Dropdown
                  title="Religion"
                  style={{width: "100%"}}
                  data={RELIGIONS}
                  value={religion}
                  onChange={(e) => onChangeReligion(e)}
                />
              </Container>

              <Container className="mt-3">
                <Dropdown
                  title="Mother Tongue"
                  style={{width: "100%"}}
                  data={MOTHER_TONGUE}
                  value={motherTongue}
                  onChange={(e) => setMotherTongue(e.target.value)}
                />
              </Container>

              <Container className="text-left mt-3">
                <label className="label-text">Where do you live?</label>
              </Container>

              <Container>
                <Dropdown
                  title="Country"
                  style={{width: "100%"}}
                  name="country"
                  data={COUNTRIES}
                  onChange={(e) => onChangeCountry(e.target.value)}
                  value={country}
                />
              </Container>

              <Container className="text-left mt-3">
                <label className="label-text">State*</label>
              </Container>

              <Container>
                <Dropdown
                  title="Search here"
                  style={{width: "100%"}}
                  data={arrState}
                  name="livingState"
                  onChange={(e) => onChangeState(e.target.value)}
                  value={livingState}
                />
              </Container>

              <Container className="text-left mt-3">
                <label className="label-text">City you live in *</label>
              </Container>

              <Container>
                <Dropdown
                  title="Search here"
                  style={{width: "100%"}}
                  data={arrCities}
                  name="city"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
              </Container>
            </Container>

            <Container className="col-sm-12 col-lg-6 mt-4 px-lg-3">
              <Container className="text-left">
                <label className="label-text">Marital Status *</label>
              </Container>

              <Container>
                <Dropdown
                  title="Choose Marital Status"
                  style={{width: "100%"}}
                  value={maritalStatus}
                  onChange={(e) => setMaritalStatus(e.target.value)}
                  data={MARITAL_STATUS}
                />
              </Container>

              <Container className="text-left mt-3">
                <label className="label-text">Community</label>
              </Container>

              <Container>
                <Dropdown
                  title="Search here"
                  style={{width: "100%"}}
                  data={arrCommunity}
                  value={community}
                  onChange={(e) => setCommunity(e.target.value)}></Dropdown>
              </Container>

              <Container className="text-left  mt-3">
                <label className="label-text">Gothra / Gothram</label>
              </Container>

              <Container>
                <Dropdown
                  title="Search here"
                  style={{width: "100%"}}
                  data={GOTRA}
                  value={gothra}
                  onChange={(e) => setGothra(e.target.value)}
                />
              </Container>

              <Container className="text-left  mt-3">
                <label className="label-text">Nakshatra</label>
              </Container>

              <Container>
                <Dropdown
                  title="Search here"
                  style={{width: "100%"}}
                  data={NAKSHATRA}
                  value={nakshatra}
                  onChange={(e) => setNakshatra(e.target.value)}
                />
              </Container>

              <Container className="text-left  mt-3">
                <label className="label-text">Add Photos</label>
              </Container>

              <Container className="d-flex justify-content-between p-0">
                <ImageSelector
                  className="mt-2"
                  name="image1"
                  onChange={(e) => handleSelectImage(e)}
                />
                <ImageSelector
                  className="mt-2"
                  name="image2"
                  onChange={(e) => handleSelectImage(e)}
                />
                <ImageSelector
                  className="mt-2"
                  name="image3"
                  onChange={(e) => handleSelectImage(e)}
                />
                <ImageSelector
                  className="mt-2"
                  name="image4"
                  onChange={(e) => handleSelectImage(e)}
                />
              </Container>

              <Container className="text-left mt-2">
                <label className="label-text" style={{fontSize: "0.9rem"}}>
                  You can add up to 4 photos
                </label>
              </Container>
            </Container>
          </Row>
        </Container>
        <Button
          isSaving={isSaving}
          className="reg-page-btn mt-4 mb-5"
          onClick={(e) => onSubmit(e)}>
          CONTINUE
        </Button>
      </Container>
    </Container>
  );
}
