import {Component} from "react";
import {Form} from "react-bootstrap";

class TextInput extends Component {
  state = {
    value: this.props.value || "",
    name: this.props.name || ""
  };

  onChange(e) {
    try {
      this.props.onChange({
        target: {
          name: this.state.name,
          value: e.target.value
        }
      });
    } catch (error) {}

    this.setState({value: e.target.value});
  }

  render() {
    return (
      <>
        <Form.Control
          name={this.state.name}
          type={this.props.type || "text"}
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder || ""}
          className="border-none light-placeholder mid-height-input"
          value={this.state.value}
          onChange={(e) => this.onChange(e)}
          isInvalid={this.props.isInvalid}
          style={this.props.style || {}}
        />
        <div className={this.props.isInvalid ? "inline-error" : "d-none"}>
          {this.props.errorText || ""}
        </div>
      </>
    );
  }
}

export default TextInput;
