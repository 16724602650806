import {Component} from "react";
import {Container, Row, Col, Dropdown, Button} from "react-bootstrap";
import findMatch from "../../Assets/find-Match.svg";
import selectIcon from "../../Assets/select_icon.svg";
import Radio from "../../templates/Radio";

export default class FindMyMatch extends Component {
  constructor() {
    super();
    this.state = {
      selectedFromAge: 19,
      selectedToAge: 19,
      selectedReligion: "Select Religion",
      arrReligion: ["Hindu", "Muslim", "Christian", "Jain"],
      selectedMotherTongue: "Select Mother Tongue",
      arrMotherTongues: ["Hindi", "English", "Tamil", "Telugu", "Urdu"],
      selectedCity: "Select City",
      arrCities: ["Pune", "Mumbai", "New Delhi"],
      gender: "Male"
    };
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  renderAge(name) {
    return [...Array(40)].map((e, age) => {
      return (
        <Container
          onClick={() => this.setState({[name]: age + 18})}
          className={`age-block p-0 pl-2 py-1 m-0 text-left ${
            this.state[name] === age + 18 ? "selected-age" : "bg-color-white"
          }`}>
          {age + 18}
          {this.state[name] === age + 18 ? (
            <img src={selectIcon} alt="" className="age-select-icon" />
          ) : (
            ""
          )}
        </Container>
      );
    });
  }

  render() {
    return (
      <Container className="position-relative">
        <Container className="text-center col-lg-10 light-text">
          <h1
            id="page-header"
            className="color-bright-pink mt-5 font-weight-normal">
            Discover Your Match
          </h1>
          <img
            id="findMyMatchImg"
            className="col-sm-10 col-lg-10 col-md-10 float-left mt-4"
            src={findMatch}
            alt="findMatch"
          />
          <Container
            id="findMyMatchForm"
            className="col-sm-10 col-lg-4 bg-color-cream py-4">
            <Row>
              <p className="label-text">I Am Looking For…</p>
            </Row>
            <Row className="justify-content-md-left">
              <Radio
                name="gender"
                value={this.state.gender}
                labels={["Male", "Female"]}
                onChange={(e) => this.handleChange(e)}
              />
            </Row>
            <Row className="p-0 m-0 mt-3 col-lg-12 col-md-12 col-sm-12 position-relative">
              <Col className="text-center m-0 p-0" style={{width: "45%"}}>
                <Row className="p-0 m-0">Age</Row>
                <Row className="p-0 m-0 mt-2">
                  <Container className="age-container p-0 w-100 hidden-scroll">
                    {this.renderAge("selectedFromAge")}
                  </Container>
                </Row>
              </Col>
              <Col className="text-center m-0 p-0" style={{width: "10%"}}>
                <Row
                  className="p-0 m-0 text-center pl-4"
                  style={{fontSize: "0.9rem"}}>
                  to
                </Row>
              </Col>
              <Col className="text-center m-0 p-0" style={{width: "45%"}}>
                <Row className="p-0 m-0">Age</Row>
                <Row className="p-0 m-0 mt-2">
                  <Container className="age-container p-0 w-100 hidden-scroll">
                    {this.renderAge("selectedToAge")}
                  </Container>
                </Row>
              </Col>
            </Row>
            <Container className="mt-3">
              <Row className="pl-3">Religion</Row>
              <Row className="mt-1">
                <Dropdown
                  onSelect={(eventKey) => {
                    this.setState({selectedReligion: eventKey});
                  }}
                  className="text-left w-100"
                  id="payment-country-ddn">
                  <Dropdown.Toggle
                    className="w-100 text-left low-height-input border-none bg-color-white label-text"
                    id="dropdown-basic">
                    {this.state.selectedReligion}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {this.state.arrReligion.map((religion) => {
                      return (
                        <Dropdown.Item eventKey={religion}>
                          {religion}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Container>
            <Container className="mt-3">
              <Row className="pl-3">Mother Tongue</Row>
              <Row className="mt-1">
                <Dropdown
                  onSelect={(eventKey) => {
                    this.setState({selectedMotherTongue: eventKey});
                  }}
                  className="text-left w-100"
                  id="payment-country-ddn">
                  <Dropdown.Toggle
                    className="w-100 text-left low-height-input border-none bg-color-white label-text"
                    id="dropdown-basic">
                    {this.state.selectedMotherTongue}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {this.state.arrMotherTongues.map((motherTongue) => {
                      return (
                        <Dropdown.Item eventKey={motherTongue}>
                          {motherTongue}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Container>
            <Container className="mt-3">
              <Row className="pl-3">City</Row>
              <Row className="mt-1">
                <Dropdown
                  onSelect={(eventKey) => {
                    this.setState({selectedCity: eventKey});
                  }}
                  className="text-left w-100"
                  id="payment-country-ddn">
                  <Dropdown.Toggle
                    className="w-100 text-left low-height-input border-none bg-color-white label-text"
                    id="dropdown-basic">
                    {this.state.selectedCity}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {this.state.arrCities.map((city) => {
                      return (
                        <Dropdown.Item eventKey={city}>{city}</Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
              <Container className="mt-4 w-100 p-0 m-0">
                <Button
                  className="bg-color-green reg-page-btn"
                  onClick={() => this.handleRegister()}>
                  FIND MY MATCH
                </Button>
              </Container>
            </Container>
            <div className="clear-float"></div>
          </Container>
          <div className="clear-float"></div>
        </Container>
      </Container>
    );
  }
}
