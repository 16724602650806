import {Container} from "react-bootstrap";
import React, {useState} from "react";
import DetailInfo from "./DetailedInfo";
import CreateProfile from "./CreateProfile";
import BottomTab from "../../templates/BottomTab";
import DetailedInfoNext from "./DetailedInfoNext";
import LastPage from "./LastPage";
import ThanksPage from "./ThanksPage";

export default function DetailInfoIndex() {
  const [pageNo, setPageNo] = useState(1);

  const onSubmit = () => {
    setPageNo((prev) => prev + 1);
  };

  const renderComponents = () => {
    switch (pageNo) {
      case 1:
        return <DetailInfo onSubmit={(param) => onSubmit(param)} />;
      case 2:
        return <CreateProfile onSubmit={(param) => onSubmit(param)} />;
      case 3:
        return <DetailedInfoNext onSubmit={(param) => onSubmit(param)} />;
      case 4:
        return <LastPage onSubmit={(param) => onSubmit(param)} />;
      default:
        return <ThanksPage />;
    }
  };

  return (
    <Container className="w-100 m-0 p-0 col-lg-12 col-md-12 col-sm-12">
      {renderComponents()}
      <Container className="col-sm-12 col-lg-5 col-md-6 my-4 pb-3">
        <BottomTab clickedTab={pageNo} tabs={4} disabled={true} />
      </Container>
    </Container>
  );
}
