import {LOGIN_SUCCESS_DATA} from "./types";

export const setUserLoginData = function (arrLoginSuccessData) {
  //Called in login.jsx page

  const ACTION = {
    //Synchronous Action Call
    type: LOGIN_SUCCESS_DATA,
    payload: arrLoginSuccessData
  };

  return ACTION;
}; //end function
