import * as objDB from "./../firebase/firebase.js";
import $ from "jquery";
import {objectSize} from "../Common/commonFunction";
import paginationQuery from "./paginationQuery";

class DBFunctions {
  constructor() {
    this.intMaxBatchProcess = 499;
    this.arrBatches = [];
    this.intBatchIndex = 0;
    this.intBatchIncrement = 0;
  }

  formatAddress(arrTable) {
    let strFullAddress = "";
    $.each(arrTable, (field, value) => {
      strFullAddress += "/" + field + "/" + value;
    });
    return strFullAddress;
  }

  createNewBatchObj() {
    if (this.intMaxBatchProcess === this.intBatchIncrement) {
      this.intBatchIncrement = 0;
      this.intBatchIndex += 1;
    }

    if (0 === this.intBatchIncrement) {
      this.arrBatches[this.intBatchIndex] = objDB.CONNECT.batch();
    }

    this.intBatchIncrement += 1;

    return this.arrBatches[this.intBatchIndex];
  }

  addDocument = (arrTables, arrSetData, isBatch = false) =>
    new Promise((resolve, reject) => {
      try {
        let strAddress = this.formatAddress(arrTables);

        if (isBatch) {
          let objBatch = this.createNewBatchObj();
          let objQuery = objDB.CONNECT.collection(strAddress).doc();
          resolve(objBatch.set(objQuery, arrSetData));
        } else {
          let objQuery = objDB.CONNECT.collection(strAddress);
          objQuery
            .add(arrSetData)
            .then((arrStatus) => {
              console.log("registration", arrStatus);
              resolve(arrStatus);
            })
            .catch((error) => {
              console.log("registration error :", error);
              throw error;
            });
        }
      } catch (error) {
        reject(error);
      }
    });

  addDocumentWithPredefinedId = (
    arrAddress,
    strDocId,
    arrUpdateData,
    boolMerge = true,
    isBatch = false
  ) =>
    new Promise((resolve, reject) => {
      try {
        let strAddress = this.formatAddress(arrAddress);
        let objQuery = objDB.CONNECT.collection(strAddress).doc(strDocId);

        if (isBatch) {
          let objBatch = this.createNewBatchObj();
          objBatch.set(objQuery, arrUpdateData, {merge: boolMerge});
          resolve(true);
        } else {
          objQuery
            .set(arrUpdateData, {merge: boolMerge})
            .then((arrStatus) => {
              resolve(arrStatus);
            })
            .catch((error) => {
              throw error;
            });
        }
      } catch (error) {
        reject(error);
      }
    });

  updateDocument = (arrTables, strDocId, arrUpdateData, isBatch = false) =>
    new Promise((resolve, reject) => {
      try {
        let strAddress = this.formatAddress(arrTables);
        let objQuery = objDB.CONNECT.collection(strAddress).doc(strDocId);

        if (isBatch) {
          let objBatch = this.createNewBatchObj();
          objBatch.update(objQuery, arrUpdateData);
          resolve(true);
        } else {
          objQuery
            .update(arrUpdateData)
            .then((arrStatus) => {
              resolve(arrStatus);
            })
            .catch((error) => {
              reject(error);
            });
        }
      } catch (error) {
        reject(error);
      }
    });

  deleteDocumentbyId = (arrAddress, strDocId, isBatch = false) =>
    new Promise((resolve, reject) => {
      try {
        let strAddress = this.formatAddress(arrAddress);
        let objQuery = objDB.CONNECT.collection(strAddress).doc(strDocId);

        if (isBatch) {
          let objBatch = this.createNewBatchObj();
          objBatch.delete(objQuery);
          resolve(true);
        } else {
          objQuery
            .delete()
            .then((arrStatus) => {
              resolve(arrStatus);
            })
            .catch((error) => {
              throw error;
            });
        }
      } catch (error) {
        reject(error);
      }
    });

  executeAllBatches = () =>
    new Promise((resolve, reject) => {
      try {
        let intExecuted = 0;
        let intExecuting = 0;
        if (this.arrBatches && this.arrBatches.length > 0) {
          this.arrBatches.forEach((batch) => {
            intExecuting += 1;
            batch
              .commit()
              .then(() => {
                intExecuted += 1;
                if (intExecuted === intExecuting) {
                  this.intMaxBatchProcess = 499;
                  this.arrBatches = [];
                  this.intBatchIndex = 0;
                  this.intBatchIncrement = 0;
                  resolve(true);
                }
              })
              .catch((error) => {
                reject(error);
              });
          });
        } else {
          reject("DB Baches Undefined");
        }
      } catch (error) {
        reject(error);
      }
    });

  updateDocumentBatch(arrBatch, arrTables, strDocId, arrUpdateData) {
    if (!arrBatch) {
      arrBatch = objDB.CONNECT.batch();
    }

    let strAddress = this.formatAddress(arrTables);
    let query = objDB.CONNECT.collection(strAddress).doc(strDocId);
    return arrBatch.update(query, arrUpdateData);
  }

  getDocuments = (
    arrTables = {},
    arrWhereConditions = [],
    arrOrderBy = {},
    arrPagination = {}
  ) =>
    new Promise((resolve, reject) => {
      let strTableAddress = this.formatAddress(arrTables);
      let objTable = objDB.CONNECT.collection(strTableAddress);

      if (arrWhereConditions.length > 0) {
        arrWhereConditions.forEach((arrWhereClause) => {
          objTable = objTable.where(
            arrWhereClause.field,
            arrWhereClause.operation,
            arrWhereClause.value
          );
        });
      }

      if (objectSize(arrOrderBy) > 0) {
        $.each(arrOrderBy, (field, strType) => {
          objTable = objTable.orderBy(field, strType);
        });
      }

      if (objectSize(arrPagination) > 0) {
        objTable = paginationQuery(objTable, arrPagination);
      }

      let arrDocument = {};
      arrDocument["records"] = {};
      arrDocument["pagination"] = {};

      objTable
        .get()
        .then((arrDocs) => {
          if (arrDocs.docs.length > 0) {
            arrDocs.docs.forEach((doc) => {
              arrDocument["records"][doc.id] = doc.data();
            });

            arrDocument["pagination"] = {
              previous_cursor: arrDocs.docs[0],
              next_cursor: arrDocs.docs[arrDocs.docs.length - 1]
            };
          }
          resolve(arrDocument);
        })
        .catch((error) => {
          console.log("Error", error);
          reject(error);
        });
    });

  getDocumentById = (arrTables, strDocId) =>
    new Promise((resolve, reject) => {
      let strAddress = this.formatAddress(arrTables);
      let arrDocument = {};
      arrDocument.exists = false;
      objDB.CONNECT.collection(strAddress)
        .doc(strDocId)
        .get()
        .then((arrDoc) => {
          if (arrDoc.exists) {
            arrDocument = arrDoc.data();
            arrDocument.id = strDocId;
            arrDocument.exists = true;
          }
          resolve(arrDocument);
        })
        .catch((error) => {
          reject(error);
        });
    });

  createBatch() {
    let arrBatch = objDB.CONNECT.batch();
    return arrBatch;
  }

  uploadFile = (objFile, strStorageLocation, strFileName) =>
    new Promise((resolve, reject) => {
      const objStorage = objDB.STORAGE.ref(strStorageLocation);
      objStorage
        .child(strFileName)
        .put(objFile)
        .then(() => {
          objStorage
            .child(strFileName)
            .getDownloadURL()
            .then((strUrl) => {
              resolve(strUrl);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });

  getFileUrl = (strStorageLocation, strFileName) =>
    new Promise((resolve, reject) => {
      const objStorage = objDB.STORAGE.ref(strStorageLocation);
      objStorage
        .child(strFileName)
        .getDownloadURL()
        .then((strUrl) => {
          resolve(strUrl);
        })
        .catch((error) => {
          reject(error);
        });
    });

  updateCount = (arrTables, strDocId, arrFields, isBatch = false) =>
    new Promise((resolve, reject) => {
      try {
        let strAddress = this.formatAddress(arrTables);
        let arrCountField = {};
        $.each(arrFields, (strFieldName, intOperation) => {
          let arithmaticOperation = objDB.FIELDVALUE.increment(intOperation);
          arrCountField[strFieldName] = arithmaticOperation;
        });
        let objQuery = objDB.CONNECT.collection(strAddress).doc(strDocId);

        if (isBatch) {
          let objBatch = this.createNewBatchObj();
          objBatch.set(objQuery, arrCountField, {merge: true});
          resolve(true);
        } else {
          objQuery
            .set(arrCountField, {merge: true})
            .then((arrStatus) => {
              resolve(arrStatus);
            })
            .catch((error) => {
              reject(error);
            });
        }
      } catch (error) {
        reject(error);
      }
    });

  getShortenLink = (strLink) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "POST",
        body: JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix: "https://vendekin.page.link",
            link: strLink
          }
        })
      };

      // firebase key currently saving static
      let strKey = "AIzaSyDskagvUJsvXW84nAfyX93z1s75SzASU20";

      fetch(
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" +
          strKey,
        options
      )
        .then((response) => response.json())
        .then((response) => {
          resolve(response.shortLink);
        })
        .catch((error) => {
          reject(error);
        });
    });
} //end class

export default DBFunctions;
