import {Container, Row, Button} from "react-bootstrap";
import $ from "jquery";
import {Component} from "react";
import circle from "../../Assets/statictics-circle.png";
import Pentagon from "../../Assets/plan-pentagon.png";
import {connect} from "react-redux";
import Square from "../../Assets/plan-square.png";
import {updateUserData} from "../../Model/users";
class BasicDetails extends Component {
  selectedPlan = "empty";

  choosePlan(plan) {
    if (plan !== this.selectedPlan) {
      $(`#${this.selectedPlan}`).removeClass("plan-container-selected");
      $(`#${this.selectedPlan}`).css({height: "24rem"});
      $(`#${plan}`).addClass("plan-container-selected");
      $(`#${plan}`).css({height: "25rem"});
      this.selectedPlan = plan;
    }
  }

  componentDidMount() {
    this.choosePlan("gold");
  }

  handleRegister() {
    updateUserData({planType: this.selectedPlan}, this.props.uid)
      .catch((error) => {
        console.log("error in update payment plan", error);
      })
      .finally(() => {
        this.props.handleRegister();
      });
  }

  render() {
    return (
      <Container className="w-100 text-center">
        <h1 id="page-header" className="color-pink px-2 font-weight-normal">
          Subscribe to Discover More
        </h1>
        <h4
          id="page-sub-header"
          className="color-pink px-2"
          style={{fontWeight: "normal"}}>
          Choose a plan
        </h4>

        <Container className="text-center col-sm-12 col-lg-10 col-md-12">
          <Row
            className="justify-content-center text-center mt-5 px-0"
            style={{minHeight: "26.5rem"}}>
            <Container
              className="col-lg-4 col-md-4 col-sm-12 bg-color-cream text-left p-4 color-bright-pink plan-container"
              style={{
                margin: "auto 0",
                fontSize: "0.75rem"
              }}
              id="basic"
              onClick={() => this.choosePlan("basic")}>
              <b>SILVER</b>
              <Container
                className="text-center my-3"
                style={{width: "8rem", height: "8em"}}>
                <img className="subscription-icon" src={circle} alt="" />
              </Container>
              <Container className="text-center mt-2 mb-4 p-0">
                <h5 className="p-0">
                  <b>INR 3000</b> / 3 Months
                </h5>
              </Container>

              <Container className="px-0 my-2">
                <b>Lorem ipsum</b>
              </Container>
              <Container className="px-0 my-2">
                <b>Dolor sit amet</b>
              </Container>
              <Container className=" my-2 px-0">
                <b>Consectetur adipisicin</b>
              </Container>
              <Container className="px-0">
                <p>Lorem ipsum</p>
              </Container>
              <Container className="px-0">
                <p>Lorem ipsum</p>
              </Container>
            </Container>

            <Container
              className="col-lg-4 col-md-4 col-sm-12 bg-color-cream text-left p-4 color-bright-pink plan-container"
              style={{
                margin: "auto 0",
                fontSize: "0.75rem"
              }}
              id="gold"
              onClick={() => this.choosePlan("gold")}>
              <b>Gold</b>
              <Container
                className="text-center my-3"
                style={{width: "8rem", height: "8em"}}>
                <img className="subscription-icon" src={Pentagon} alt="" />
              </Container>
              <Container className="text-center mt-2 mb-4 p-0">
                <h5 className="p-0">
                  <b>INR 6000</b> / 6 Months
                </h5>
              </Container>

              <Container className="px-0 my-2">
                <b>Lorem ipsum</b>
              </Container>
              <Container className="px-0 my-2">
                <b>Dolor sit amet</b>
              </Container>
              <Container className=" my-2 px-0">
                <b>Consectetur adipisicin</b>
              </Container>
              <Container className="px-0">
                <p>Lorem ipsum</p>
              </Container>
              <Container className="px-0">
                <p>Lorem ipsum</p>
              </Container>
            </Container>

            <Container
              className="col-lg-4 col-md-4 col-sm-12 bg-color-cream text-left  p-4 color-bright-pink plan-container"
              style={{
                margin: "auto 0",
                fontSize: "0.75rem"
              }}
              id="platinum"
              onClick={() => this.choosePlan("platinum")}>
              <b>Platinum</b>
              <Container
                className="text-center my-3"
                style={{width: "8rem", height: "8em"}}>
                <img className="subscription-icon" src={Square} alt="" />
              </Container>
              <Container className="text-center mt-2 mb-4 p-0">
                <h5 className="p-0">
                  <b>INR 10000</b> / 12 Months
                </h5>
              </Container>

              <Container className="px-0 my-2">
                <b>Lorem ipsum</b>
              </Container>
              <Container className="px-0 my-2">
                <b>Dolor sit amet</b>
              </Container>
              <Container className=" my-2 px-0">
                <b>Consectetur adipisicin</b>
              </Container>
              <Container className="px-0">
                <p>Lorem ipsum</p>
              </Container>
              <Container className="px-0">
                <p>Lorem ipsum</p>
              </Container>
            </Container>
          </Row>
        </Container>
        <Row className="justify-content-md-center text-center mt-4 mb-5">
          <Container>
            <Button
              className="bg-color-green reg-page-btn"
              onClick={() => this.handleRegister()}>
              SUBSCRIBE
            </Button>
          </Container>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (reduxStore) => {
  return {
    uid: reduxStore.uid
  };
};

export default connect(mapStateToProps, null)(BasicDetails);
