import "./App.css";
import "./App.css";
import NavBar from "./Components/NavBar";
import {Route, BrowserRouter, Switch} from "react-router-dom";
import Home from "./Components/Home.jsx";
import Registration from "./Components/Form/Registration.jsx";
import Login from "./Components/Login/Login";
import Footer from "./Components/Footer";
import DetailedInfo from "./Components/DetailedInfo/DetailInfoIndex";
import HomeProfile from "./Components/Profile/HomeProfile";

export default function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/register/" component={Registration} />
        <Route path="/login/" component={Login} />
        <Route path="/detailed_info/" component={DetailedInfo} />
        <Route path="/profile/" component={HomeProfile} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}
