import React, {Component} from "react";
import behavioralTest from "../Assets/home_behaviour_test.PNG";
import compatibleTest from "../Assets/home_compatible.PNG";
import registerUser from "../Assets/home_register.PNG";
import $ from "jquery";

class BehavioralInfo extends Component {
  state = {
    index: 0
  };

  selectedPointer = "";

  componentDidMount() {
    this.selectPointer("mid-color-pointer");
  }

  handleScroll() {
    let widthScroll = $("#carousel-container").scrollLeft();
    $("#left-color-pointer").removeClass("coro-pointer-selected");
    $("#mid-color-pointer").removeClass("coro-pointer-selected");
    $("#right-color-pointer").removeClass("coro-pointer-selected");

    if (widthScroll < 250) {
      $("#left-color-pointer").addClass("coro-pointer-selected");
    } else if (widthScroll >= 250 && widthScroll < 500) {
      $("#mid-color-pointer").addClass("coro-pointer-selected");
    } else if (widthScroll > 500) {
      $("#right-color-pointer").addClass("coro-pointer-selected");
    }
  }

  selectPointer(id) {
    if (id !== this.selectedPointer) {
      // if (this.selectedPointer)
      //   $("#" + this.selectedPointer).removeClass("coro-pointer-selected");
      // this.selectedPointer = id;
      // $("#" + this.selectedPointer).addClass("coro-pointer-selected");

      switch (id) {
        case "left-color-pointer":
          $("#carousel-container").animate({scrollLeft: "0%"}, 500);
          break;
        case "mid-color-pointer":
          $("#carousel-container").animate({scrollLeft: "280%"}, 500);
          break;
        case "right-color-pointer":
          $("#carousel-container").animate({scrollLeft: "600%"}, 500);
          break;
        default:
          break;
      }
    }
  }

  render() {
    return (
      <div className="text-center">
        <h1
          id="page-header"
          className="color-bright-pink mt-5 font-weight-normal">
          Our Simple Three Step Process
        </h1>
        <div
          className="hidden-scroll"
          onScroll={() => this.handleScroll()}
          id="carousel-container"
          style={{
            minWidth: "100%",
            overflowX: "scroll",
            marginTop: "2rem",
            padding: "0 18vw"
          }}>
          <div id="carousel-scrollbar" style={{display: "inline-flex"}} class>
            <div
              className="bg-color-cream text-center color-bright-pink pb-1"
              style={{
                margin: "auto 0",
                padding: "0",
                width: "60vw"
              }}>
              <img src={registerUser} className="w-100" alt="" />

              <h4 className="color-pink-bright mt-5">Register</h4>

              <p className="label-text mt-3 px-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              </p>
            </div>

            <div
              className="bg-color-cream text-center mx-5 color-bright-pink pb-1"
              style={{
                margin: "auto 0",
                padding: "0",
                width: "60vw"
              }}>
              <img src={behavioralTest} className="w-100" alt="" />

              <h4 className="color-pink-bright mt-5">Behavioural Test</h4>

              <p className="label-text mt-3 px-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              </p>
            </div>

            <div
              className="bg-color-cream text-center color-bright-pink pb-1"
              style={{
                margin: "auto 0",
                padding: "0",
                width: "60vw"
              }}>
              <img src={compatibleTest} className="w-100" alt="" />

              <h4 className="color-pink-bright mt-5">Compatibility Match</h4>

              <p className="label-text mt-3 px-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              </p>
            </div>
          </div>
        </div>

        <div className="d-inline-flex mt-4">
          <div
            id="left-color-pointer"
            className="coro-pointer mr-2"
            onClick={() => this.selectPointer("left-color-pointer")}></div>
          <div
            id="mid-color-pointer"
            className="coro-pointer mr-2"
            onClick={() => this.selectPointer("mid-color-pointer")}></div>
          <div
            id="right-color-pointer"
            className="coro-pointer"
            onClick={() => this.selectPointer("right-color-pointer")}></div>
        </div>
      </div>
    );
  }
}

export default BehavioralInfo;
