export const LOGIN_SUCCESS_DATA      =  'login_success_data';
export const USER_GROUP_PERMISSIONS  =  'user_group_permissions';
export const PAYOUT_DATA 		     =  'payout_data';
export const USER_LOGOUT             =  'user_logout';
export const CLEAR_PERMISSIONS 		 =  'clear_permissions';
export const CLEAR_PAYOUT_DATA 		 =  'clear_payout_data';
export const SUPPORT_DATA 		     =  'support_data';
export const CUSTOMER_DATA 		     =  'customer_data';
export const ORDER_DATA 		     =  'order_data';
export const CLEAR_ORDER_DATA        =  'clear_order_data';
