import React from "react";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import Cropper from "react-easy-crop";
import getCroppedImg from "./CropImage";
import "./styles.css";
import Skeleton from "@material-ui/lab/Skeleton";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
const minZoom = 1;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ImageCropper extends React.Component {
  state = {
    imageSrc: this.props.src,
    crop: {x: 0, y: 0},
    zoom: minZoom,
    aspect: 3 / 4,
    croppedAreaPixels: null,
    croppedImage: null
  };

  onCropChange = (crop) => {
    this.setState({crop});
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({croppedAreaPixels});
  };

  onZoomChange = (zoom) => {
    this.setState({zoom});
  };

  showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      this.props.src,
      this.state.croppedAreaPixels
    );

    this.props.onCrop(croppedImage);
  };

  onCancelCrop = () => {
    this.props.onCrop(false);
  };

  handleClose = () => {
    this.setState({croppedImage: null});
  };

  render() {
    const {classes} = this.props;
    return (
      <div className="crop-holder">
        <Dialog
          fullScreen
          open={true}
          onClose={this.props.onClose}
          TransitionComponent={Transition}>
          <div className="crop-container">
            {this.props.src ? (
              <Cropper
                minZoom={minZoom}
                image={this.props.src}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                restrictPosition={false}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
              />
            ) : (
              <Skeleton
                variant="rect"
                width={"100%"}
                height={"100%"}
                animation="wave"
              />
            )}
          </div>
          <div className="controls">
            <Slider
              value={this.state.zoom}
              min={minZoom}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => this.onZoomChange(zoom)}
              // classes={{container: "slider"}}
            />
            <Button
              onClick={this.onCancelCrop}
              variant="contained"
              color="primary"
              classes={{root: classes.cropButton}}>
              Cancel
            </Button>
            <Button
              onClick={this.showCroppedImage}
              variant="contained"
              color="primary"
              classes={{root: classes.cropButton}}>
              Crop
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

const styles = {
  cropButton: {
    flexShrink: 0,
    marginLeft: 16
  }
};

export default withStyles(styles)(ImageCropper);
