import React from "react";
import {Container} from "react-bootstrap";
import {useHistory} from "react-router";
import mobileMatch from "../../Assets/mobile_match.svg";

export default function ThanksPage() {
  const history = useHistory();

  const onTakeTest = () => {
    history.push("/");
  };

  return (
    <Container className="col-lg-12 text-center">
      <Container className="text-center">
        <h1 className="color-pink mt-4 font-weight-normal page-header">
          Thank you for completing the basic details!
        </h1>
      </Container>
      <Container className="w-100 col-lg-10 col-md-12 col-sm-12  mt-3">
        <Container className="col-lg-6 mt-5">
          <p>
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est eopksio laborum. Sed ut
            perspiciatis unde omnis istpoe natus error sit voluptatem
            accusantium doloremque eopsloi laudantium, totam rem
          </p>

          <Container
            className="color-bright-pink mt-3 pointer"
            onClick={onTakeTest}>
            <h3>
              <u>TAKE THE TEST</u>
            </h3>
          </Container>
        </Container>

        <Container className="col-lg-7" onClick={onTakeTest}>
          <img
            src={mobileMatch}
            alt=""
            className="w-100"
            style={{marginTop: "-6rem"}}
          />
        </Container>
      </Container>
    </Container>
  );
}
