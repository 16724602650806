import React, {Component} from "react";
import {Container, Row, Button} from "react-bootstrap";
import behavioralTest from "../Assets/home_behaviour_test.PNG";
import compatibleTest from "../Assets/home_compatible.PNG";
import registerUser from "../Assets/home_register.PNG";
class BehavioralInfo extends Component {
  handleRegister() {}

  state = {};
  render() {
    const button = (
      <Button
        className="bg-color-green reg-page-btn"
        onClick={() => this.handleRegister()}>
        REGISTER NOW
      </Button>
    );
    return (
      <Container className="w-100 text-center">
        <Row className="col-lg-12 text-center px-0 mx-0">
          <Container className="px-0 mx-0">
            <h1
              id="page-header"
              className="color-bright-pink mt-5 font-weight-normal">
              Our Simple Three Step Process
            </h1>
          </Container>
        </Row>
        <Container className="justify-content-center col-lg-10 col-md-10 text-center mt-5">
          <Row>
            <Container className="col-lg-4">
              <Container
                className="bg-color-cream text-center mr-3 color-bright-pink pb-5"
                style={{
                  margin: "auto 0",
                  padding: "0"
                }}
                onClick={() => this.choosePlan("basic")}>
                <img src={registerUser} className="w-100" alt="" />

                <h3 className="color-pink-bright mt-5">Register</h3>

                <p className="label-text mt-3 px-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do
                </p>
              </Container>
            </Container>
            <Container className="col-lg-4">
              <Container
                className="bg-color-cream text-center mr-3 color-bright-pink pb-5"
                style={{
                  margin: "auto 0",
                  padding: "0"
                }}
                onClick={() => this.choosePlan("gold")}>
                <img src={behavioralTest} className="w-100" alt="" />

                <h3 className="color-pink-bright mt-5">Behavioural Test</h3>

                <p className="label-text mt-3 px-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do
                </p>
              </Container>
            </Container>
            <Container className="col-lg-4">
              <Container
                className="bg-color-cream text-center mr-3 color-bright-pink pb-5"
                style={{
                  margin: "auto 0",
                  padding: "0"
                }}
                onClick={() => this.choosePlan("gold")}>
                <img src={compatibleTest} className="w-100" alt="" />

                <h3 className="color-pink-bright mt-5">Behavioural Test</h3>

                <p className="label-text mt-3 px-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do
                </p>
              </Container>
            </Container>
          </Row>
          <Row className="col-lg-12 text-center my-5 p-0 mx-0">
            <Container className="p-0">{button}</Container>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default BehavioralInfo;
