const paginationQuery = (ObjCollection, arrPagination) => {
  try {
    if (arrPagination.strAction === "initial") {
      ObjCollection = ObjCollection.limit(arrPagination.intLimit);
      return ObjCollection;
    }

    if (arrPagination.strAction === "next") {
      ObjCollection = ObjCollection.startAfter(arrPagination.strStart).limit(
        arrPagination.intLimit
      );
      return ObjCollection;
    }

    if (arrPagination.strAction === "previous") {
      ObjCollection = ObjCollection.startAt(arrPagination.strStart).limit(
        arrPagination.intLimit
      );
      return ObjCollection;
    }
  } catch (error) {
    return ObjCollection;
  }
};

export default paginationQuery;
