export const objectSize = function (obj) {
  return Object.keys(obj).length;
}; //end function

export const getDbObject = () => {
  const db = require("../Model/DBFunctions").default;
  return new db();
};

export const getStorageObject = () => {
  return require("../firebase/firebase").STORAGE;
};

export const getRandomNumber = (digit) => {};

export const getRandomString = (length = 6) => {
  return Math.random().toString(20).substr(2, length);
};
