import React, {useState, useEffect} from "react";
import {Container} from "react-bootstrap";

function handleClick(clickedTab, setClickTab, props) {
  if (!props.disabled) {
    setClickTab(clickedTab);
    props.onClick(clickedTab);
  }
}

export default function BottomTab(props) {
  const [clickTab, setClickTab] = useState(props.clickedTab || 1);
  useEffect(() => {
    setClickTab(props.clickedTab);
  }, [props.clickedTab]);

  return (
    <Container className="text-center d-flex justify-content-between">
      {[...Array(props.tabs)].map((e, index) => {
        return (
          <div
            key={index + 1}
            onClick={() => handleClick(index + 1, setClickTab, props)}
            style={{width: 100 / props.tabs + "%"}}
            className={
              "tab " +
              (clickTab === index + 1 ? "bg-color-green" : "bg-color-grey")
            }
          />
        );
      })}
    </Container>
  );
}
