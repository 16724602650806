import DBFunctions from "./DBFunctions";
import {collectionName} from "../Common/constants";
const objDBFunctions = new DBFunctions();

export const setUserRelatives = async (arrData, isBatch = false, db = null) => {
  try {
    if (null === db) {
      db = objDBFunctions;
    }

    const response = await db.addDocument(
      {[collectionName.RELATIVES]: ""},
      arrData,
      isBatch
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserData = async (docId) => {
  try {
    const response = await objDBFunctions.getDocumentById(
      {[collectionName.CONSUMER]: ""},
      docId
    );
    return response;
  } catch (error) {
    return {};
  }
};

export const getUserDataByCondition = async (condition) => {
  try {
    const response = await objDBFunctions.getDocuments(
      {[collectionName.CONSUMER]: ""},
      condition
    );
    return response;
  } catch (error) {
    console.log(error);
    return {};
  }
};
