import React, {useState, useCallback} from "react";
import {Container, Row} from "react-bootstrap";
import Dropdown from "../../templates/Dropdown";
import TextArea from "../../templates/TextArea";
import Radio from "../../templates/Radio";
import Button from "../../templates/Button";

export default React.memo(DetailedInfoNext);

function DetailedInfoNext(props) {
  const [futurePlans, setFuturePlans] = useState("");
  const [arrFeet, setArrFeet] = useState([]);
  const [arrInch, setArrInch] = useState([]);

  const onSubmit = (e) => {
    props.onSubmit();
  };

  useCallback(() => {
    const arrFeet = [...Array(10)].map((e, feet) => {
      return feet + 1;
    });

    setArrFeet(arrFeet);

    const arrInch = [...Array(12)].map((e, inch) => {
      return inch + 1;
    });

    setArrInch(arrInch);
  }, []);

  return (
    <Container className="col-lg-12 text-center">
      <Container className="text-center">
        <h1 className="color-pink mt-4 font-weight-normal page-header">
          Just a few more steps
        </h1>

        <h4 className="color-pink font-weight-normal mt-2 page-sub-header">
          Please add your career and education details and lifestyle information
        </h4>
      </Container>

      <Container className="w-100 col-lg-10 col-md-12 col-sm-12 bg-color-cream mt-3">
        <Container className="col-lg-10 col-md-12 col-sm-12 px-0 px-lg-5">
          <Row className="col-sm-12 col-lg-12 m-0 p-0">
            <Container className="col-sm-12 col-lg-6 mt-4 px-lg-3">
              <Container>
                <Container className="text-left">
                  <label className="label-text">Education Level</label>
                </Container>

                <Container>
                  <Dropdown
                    title="Choose Marital Status"
                    style={{width: "100%"}}
                    // value={martialStatus}
                    // onChange={(e) => setMartialStatus(e.target.value)}
                    //data={YESNO}
                  />
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">Education Field</label>
                </Container>

                <Container>
                  <Dropdown
                    title="Choose Marital Status"
                    style={{width: "100%"}}
                    // value={martialStatus}
                    // onChange={(e) => setMartialStatus(e.target.value)}
                    //data={YESNO}
                  />
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">You Work With</label>
                </Container>

                <Container>
                  <Dropdown
                    title="Choose Marital Status"
                    style={{width: "100%"}}
                    // value={martialStatus}
                    // onChange={(e) => setMartialStatus(e.target.value)}
                    //data={YESNO}
                  />
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">As</label>
                </Container>

                <Container>
                  <Dropdown
                    title="Choose Marital Status"
                    style={{width: "100%"}}
                    // value={martialStatus}
                    // onChange={(e) => setMartialStatus(e.target.value)}
                    //data={YESNO}
                  />
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">You Work With (Current)</label>
                </Container>

                <Container>
                  <Dropdown
                    title="Choose Marital Status"
                    style={{width: "100%"}}
                    // value={martialStatus}
                    // onChange={(e) => setMartialStatus(e.target.value)}
                    //data={YESNO}
                  />
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">Your Employer Name</label>
                </Container>

                <Container>
                  <Dropdown
                    title="Choose Marital Status"
                    style={{width: "100%"}}
                    // value={martialStatus}
                    // onChange={(e) => setMartialStatus(e.target.value)}
                    //data={YESNO}
                  />
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">Your Annual Income</label>
                </Container>

                <Container>
                  <Dropdown
                    title="Choose Marital Status"
                    style={{width: "100%"}}
                    // value={martialStatus}
                    // onChange={(e) => setMartialStatus(e.target.value)}
                    //data={YESNO}
                  />
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">Your future plans</label>
                </Container>

                <Container>
                  <TextArea
                    name="futurePlans"
                    value={futurePlans}
                    onChange={(e) => setFuturePlans(e.target.value)}
                    placeholder="Type something… (Up to 1000 words)"
                    maxLength="1000"
                  />
                </Container>
              </Container>
            </Container>

            <Container className="col-sm-12 col-lg-6 mt-4 px-lg-3 text-left">
              <Container>
                <Container>
                  <label className="label-text">Identification</label>
                </Container>

                <Container style={{fontSize: "0.9rem"}}>
                  <u>Choose A File</u>
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">Your Diet</label>
                </Container>

                <Container>
                  <Radio
                    labels={[
                      "Vegetarian",
                      "Non-Vegetarian",
                      "Occasionally Non-Vegetari",
                      "Eggetarian",
                      "Jain",
                      "Vegan"
                    ]}
                    value={"Vegetarian"}
                  />
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">Height</label>
                </Container>

                <Container className="d-flex">
                  <Dropdown
                    title="Feet"
                    style={{width: "45%", marginRight: "5%"}}
                    data={arrFeet}
                    // value={martialStatus}
                    // onChange={(e) => setMartialStatus(e.target.value)}
                    //data={YESNO}
                  />

                  <Dropdown
                    title="Inches"
                    style={{width: "45%"}}
                    data={arrInch}
                    // value={martialStatus}
                    // onChange={(e) => setMartialStatus(e.target.value)}
                    //data={YESNO}
                  />
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">Body Type</label>
                </Container>

                <Container className="d-flex">
                  <Radio
                    labels={["Slim", "Athletic", "Average", "Heavy"]}
                    value={"Slim"}
                  />
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">Skin Tone</label>
                </Container>

                <Container className="d-flex">
                  <Radio
                    labels={["Slim", "Athletic", "Average", "Heavy"]}
                    value={"Slim"}
                  />
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">Do you smoke?</label>
                </Container>

                <Container className="d-flex">
                  <Radio
                    labels={["Yes", "No", "Occasionally"]}
                    value={"Slim"}
                  />
                </Container>
              </Container>

              <Container>
                <Container className="text-left">
                  <label className="label-text">Do you drink?</label>
                </Container>

                <Container className="d-flex">
                  <Radio
                    labels={["Yes", "No", "Occasionally"]}
                    value={"Slim"}
                  />
                </Container>
              </Container>
            </Container>
          </Row>
        </Container>
        <Button className="reg-page-btn mt-4 mb-5" onClick={(e) => onSubmit(e)}>
          CONTINUE
        </Button>
      </Container>
    </Container>
  );
}
