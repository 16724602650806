import React from "react";
import {Container, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import ProfileDummy from "../../Assets/profileDummy.svg";
import Dropdown from "../../templates/Dropdown";
import SubHeart from "../../Assets/sub_heart.svg";
import blackCoat from "../../Assets/blackCoat.svg";
import greenEye from "../../Assets/greenEye.svg";

export default function HomeProfile() {
  const {familyName} = useSelector((state) => state.loginReducer);

  return (
    <Container className="col-lg-12 col-sm-12 py-5 px-5">
      <Row className="m-0 p-0">
        <Container className="col-lg-2 col-sm-12">
          <Container style={{height: "15rem", width: "12rem"}}>
            <img src={ProfileDummy} className="w-100" />
          </Container>
        </Container>
        <Container className="col-lg-6 col-sm-12 text-left">
          <h2 className="color-bright-pink">
            <b>{familyName}</b>
          </h2>

          <div className="mt-4 ml-2 w-50 text-left">
            <label className="label-text">You are</label>
            <Dropdown
              data={["Self", "Relative"]}
              title="Choose"
              style={{backgroundColor: "#FAFAFA"}}></Dropdown>
          </div>

          <Row className="col-lg-10 mt-4">
            <Container
              className="col-lg-4 color-light-green d-flex"
              style={{borderRight: "1px solid #CCCCCC"}}>
              <Container className="col-lg-5">
                <img src={SubHeart} className="w-100" />
              </Container>
              <Container className="col-lg-7 p-0">
                <h5 style={{lineHeight: 0.9}}>
                  <u>Subscribe to connect to people</u>
                </h5>
              </Container>
            </Container>
            <Container
              className="col-lg-4  d-flex"
              style={{borderRight: "1px solid #CCCCCC"}}>
              <Container className="col-lg-5">
                <img src={blackCoat} className="w-100" />
              </Container>
              <Container className="col-lg-7 p-0" style={{lineHeight: 0.9}}>
                <h5 className="color-bright-pink">
                  <b>40/100</b>
                </h5>
                <h5 style={{color: "#7F7F7F"}}>Contacts Viewed</h5>
              </Container>
            </Container>
            <Container
              className="col-lg-4  d-flex"
              style={{borderRight: "1px solid #CCCCCC"}}>
              <Container className="col-lg-5">
                <img src={greenEye} className="w-100" />
              </Container>
              <Container className="col-lg-7 p-0" style={{lineHeight: 0.9}}>
                <h5 className="color-bright-pink">
                  <b>108</b>
                </h5>
                <h5 style={{color: "#7F7F7F"}}>Profile Views</h5>
              </Container>
            </Container>
          </Row>

          <Container className="mt-5">
            <h2 className="color-bright-pink">Your Behavioural Test Result</h2>
            <p>
              Nemo ernim ipsam voluptatem quia voluptas sit aspernatur aut odit
              aut fugit, sedopk quia consequuntur magni dolores eos qui
              rationesopl voluptatem sequi nesciunt. Neque porro quisquameo est,
              qui dolorem ipsum quia dolor sit amet, eopsmiep consectetur,
              adipisci velit, seisud. quia non numquam eius modi tempora
              incidunt ut labore et dolore wopeir magnam aliquam quaerat
              voluptatem eoplmuriquisqu.
            </p>
          </Container>
        </Container>
        <Container className="col-lg-4 col-sm-12">3</Container>
      </Row>
    </Container>
  );
}
