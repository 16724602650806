export const uploadFileWithName = (
  objDBStorage,
  strStoragePath,
  strFileName,
  file
) =>
  new Promise((resolve, reject) => {
    const objStorage = objDBStorage.ref(strStoragePath);
    objStorage
      .child(strFileName)
      .getDownloadURL()
      .then((strUrl) => {
        resolve(strUrl);
      })
      .catch((error) => {
        objStorage
          .child(strFileName)
          .put(file)
          .then((snapshot) => {
            objStorage
              .child(strFileName)
              .getDownloadURL()
              .then((strUrl) => {
                resolve(strUrl);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
  }); //end function
