import {useState, useEffect} from "react";
import {Container, Form, Row, FormCheck} from "react-bootstrap";
import loginBackground from "../../Assets/login_back.svg";
import {AUTH, CONNECT, RecaptchaVerifier} from "../../firebase/firebase";
import {getUserData} from "../../Model/users";
import {useDispatch} from "react-redux";
import {setUserLoginData} from "../../Redux/actions";
import {useHistory} from "react-router-dom";
import Button from "../../templates/Button";
import {NULL_STRING, APICALLURL} from "..//../Common/constants";
import {NotificationManager} from "react-notifications";

import {useSelector} from "react-redux";

var recaptchaVerifier = null;
var captchaConfirm = null;

function renderCaptch() {
  recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
    size: "invisible",
    callback: (response) => {
      //.isCaptchaVerified = true;
      // this.verifyCode();
    }
    // "expired-callback": () => {
    //     this.isCaptchaVerified = false;
    //     alert("Solve Captcha Again!");
    // },
  });

  recaptchaVerifier.render();
}

async function onLogin(
  email,
  password,
  dispatch,
  history,
  setPassword,
  setIsMobileLogin,
  isMobileLogin,
  setIsSaving,
  loginReducer
) {
  if (email.length === 10) {
    setIsSaving(true);
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin':'*'
      },
      body: JSON.stringify({ phoneNumber: "+91" + email})
    };
    try {
      const res = await fetch(APICALLURL.userAuth, options);
      console.log(res);
      let result = "";
      try {
        result = await res.json();
      } catch (error) {
        result = await res;
      }
      console.log("result");
      console.log(result);
      if(result.statusCode === 200){
        email= result.body.user.email
      }else{
        email= "";
        setIsSaving(false);
      }
    } catch (error) {
      console.log("Error ", error);
    }

    // if (!isMobileLogin) {
    //   CONNECT.collection("consumer")
    //     .where("mobile", "==", `+91${email}`)
    //     .get()
    //     .then((docs) => {
    //       if (0 < docs.docs.length) {
    //         let data = docs.docs[0].data();
    //         dispatch(
    //           setUserLoginData({
    //             loginStatus: false,
    //             uid: docs.docs[0].id,
    //             userName: data.userName,
    //             familyName: data.familyName,
    //             mobile: data.mobile,
    //             self: data.self,
    //             gender: data.gender,
    //             email: data.email,
    //             registered: data.registered
    //           })
    //         );

    //         AUTH.signInWithPhoneNumber(`+91${email}`, recaptchaVerifier)
    //           .then((confirmationResult) => {
    //             captchaConfirm = confirmationResult;
    //             setPassword("");
    //             setIsMobileLogin(true);
    //             setIsSaving(false);
    //           })
    //           .catch((error) => {
    //             console.log("error", error);
    //           });
    //       } else {
    //         alert("You are not registered with us!");
    //         setIsSaving(false);
    //       }
    //     });
    // } else {
    //   verifyCode(password, setIsSaving, dispatch, history, loginReducer);
    // }
  }
  AUTH.signInWithEmailAndPassword(email, password)
    .then((response) => {
      getUserData(response.user.uid).then((userData) => {
        dispatch(
          setUserLoginData({...userData, loginStatus: true, uid: userData.id})
        );

        history.push("home");
      });
    })
    .catch((error) => {
      switch (error.code) {
        case "auth/user-not-found":
          alert("You are not registered!");
          break;
        case "auth/wrong-password":
          alert("Invalid login detail!");
          break;
        case "auth/invalid-email":
          alert("You are not registered with us!");
          break;
        default:
          alert("something went wrong");
      }
    });
}

function verifyCode(authCode, setIsSaving, dispatch, history, loginReducer) {
  if (authCode.length !== 6) {
    alert("Invalid Verification Code!");
    return;
  }
  setIsSaving(true);
  captchaConfirm
    .confirm(authCode)
    .then((response) => {
      // User signed in successfully.

      dispatch(
        setUserLoginData({
          ...loginReducer,
          loginStatus: true
        })
      );

      history.push("home");
      // ...
    })
    .catch(function (error) {
      // User couldn't sign in (bad verification code?)
      // ...
      alert("Wrong Verification code!");
    })
    .finally(() => {
      setIsSaving(false);
    });
}

async function changePassword(email) {
  let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (email !== NULL_STRING && filter.test(email)) {
    var r = window.confirm(
      "Email will be send on " +
        email +
        ". Are you sure to reset your password?"
    );
    if (r === true) {
      AUTH.sendPasswordResetEmail(email)
        .then(function () {
          NotificationManager.success("Please check your email");
        })
        .catch(function (error) {
          if (error.code === "auth/user-not-found") {
            NotificationManager.error("Email doesn't exists");
          }
        });
    }
  } else {
    NotificationManager.error("Please Enter valid Email");
  }
}
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isMobileLogin, setIsMobileLogin] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const loginReducer = useSelector((state) => state.loginReducer);

  useEffect(() => {
    renderCaptch();
  }, []);

  return (
    <Container className="w-100 text-center col-12 pb-4">
      <h1 id="page-header" className="color-pink my-4 font-weight-normal">
        Member Login
      </h1>
      <Container className="w-100 col-lg-10 col-md-8 col-sm-12 text-center">
        <Container className="text-center col-lg-7 col-md-10 col-sm-12 pt-2 position-relative">
          <img
            id="login-bg"
            alt="bg"
            src={loginBackground}
            className="w-100 mt-5"
          />

          <Container
            id="login-container"
            className="col-sm-10 col-lg-6 bg-color-cream px-4 py-4">
            <Row className="m-0 my-2">
              <Form.Control
                type="email"
                placeholder="Registered email ID / Mobile Number"
                className="border-none light-placeholder w-100"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isMobileLogin}
              />
            </Row>
            <Row className="m-0 my-2">
              <Form.Control
                type="password"
                placeholder={!isMobileLogin ? "Password" : "Enter OTP"}
                className="border-none light-placeholder w-100"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Row>
            <Row
              className="col-lg-10 color-green float-right"
              onClick={() => changePassword(email)}
              id="forgot_password"
              style={{cursor: "pointer"}}>
              Forgot Password?
            </Row>
            <Button
              className="bg-color-green reg-page-btn mt-2"
              isSaving={isSaving}
              onClick={() =>
                onLogin(
                  email,
                  password,
                  dispatch,
                  history,
                  setPassword,
                  setIsMobileLogin,
                  isMobileLogin,
                  setIsSaving,
                  loginReducer
                )
              }>
              LOGIN
            </Button>
            <FormCheck
              className="col-lg-6 color-green mt-2"
              label="Stay signed in"></FormCheck>
          </Container>
        </Container>
      </Container>
      <h1 id="page-header" className="color-pink mt-3 mb-2 font-weight-normal">
        NEW TO HUMBLE MATCH?
      </h1>
      <h3
        className="color-bright-pink text-decoration"
        onClick={(e) => onLogin(e, email, password)}>
        <u>Register Here</u>
      </h3>
      <div id="recaptcha-container"></div>
    </Container>
  );
}
