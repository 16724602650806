import {Component} from "react";
//  import {Container, Row} from "react-bootstrap";

class Radio extends Component {
  constructor(props){
    super(props);
    this.state = {
      arrLabels: this.props.labels || [],
      value: this.props.value || ""
    };
    this.onClick = this.onClick.bind(this);
  }
  

  onClick(label) {
    this.props.onChange({
      target: {
        value: label,
        name: this.props.name
      }
    });
    this.setState({value: label});
  }

  componentDidUpdate(oldProps,oldState) {
    if( oldProps.value !== this.props.value ) {
     	this.setState({
        value: this.props.value
      })
      
    }
   
}
  render() {
    return (
      <div className="w-100 d-flex flex-wrap">
        {this.state.arrLabels.map((label) => {
          
    
          return (
            <div
              className="d-flex mr-3"
              key={label}
              onClick={() => this.onClick(label)}>
              <div className="radio-button mr-2">
                {this.state.value === label ? (
                  <div className="radio-btn-child" />
                ) : null}
              </div>
              <label className="label-text mb-3" style={{marginTop: "-0.3rem"}}>
                {label}
              </label>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Radio;
