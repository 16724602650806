import React, {useState} from "react";
import {Container, Row, Col, FormCheck} from "react-bootstrap";
import TextArea from "../../templates/TextArea";
import TextInput from "../../templates/TextInput";
import Button from "../../templates/Button";
import {updateUserData} from "../../Model/users";
import {useSelector} from "react-redux";

export default function LastPage(props) {
  const {uid, mobile} = useSelector((state) => state.loginReducer);

  const [isSaving, setSaving] = useState(false);
  const [hobbies, setHobbies] = useState("");
  const [selfDescription, setSelfDescription] = useState("");
  const [lifeOfDay, setLifeOfDay] = useState("");
  const [weekendDescription, setWeekendDescription] = useState("");
  const [agree, setAgree] = useState(false);
  const [number, setNumber] = useState(mobile);

  const onSubmit = () => {
    if (agree) {
      setSaving(true);

      let data = {
        description: {
          selfDescription,
          weekendDescription,
          hobbies,
          lifeOfDay,
          informationAgreed: agree,
          mobile: number
        }
      };

      updateUserData(data, uid)
        .then(() => {
          props.onSubmit();
        })
        .catch((error) => {
          setSaving(false);
        });
    } else {
      document.getElementById("agree").focus();
    }
  };

  return (
    <Container className="w-100 col-lg-12 text-center">
      <Container className="text-center">
        <h1 className="color-pink mt-4 font-weight-normal page-header">
          One last thing
        </h1>

        <h4 className="color-pink font-weight-normal mt-2 page-sub-header">
          Describe yourself in a few words
        </h4>
      </Container>

      <Container className="w-100 col-lg-10 col-sm-12 bg-color-cream">
        <Container className="col-lg-10 col-sm-12 px-0 px-lg-5">
          <Row className="col-sm-12 col-lg-12 m-0 p-0 text-left">
            <Col className="col-lg-6 col-sm-12 mt-4">
              <Container className="text-left">
                <label className="label-text">
                  Describe yourself in a few words
                </label>
              </Container>

              <Container>
                <TextArea
                  name="selfDescription"
                  value={selfDescription}
                  onChange={(e) => setSelfDescription(e.target.value)}
                  placeholder="Type something… (Up to 4000 words)"
                  maxLength="4000"
                  rows={4}
                />
              </Container>
            </Col>

            <Col className="col-lg-6 col-sm-12 mt-4">
              <Container className="text-left">
                <label className="label-text">Your Weekends</label>
              </Container>
              <Container>
                <TextArea
                  name="weekendDescription"
                  value={weekendDescription}
                  onChange={(e) => setWeekendDescription(e.target.value)}
                  placeholder="What does your usual weekend look like? (Up to 3000 words)"
                  maxLength="3000"
                  rows={4}
                />
              </Container>
            </Col>

            <Col className="col-lg-6 col-sm-12 mt-4">
              <Container className="text-left">
                <label className="label-text">Hobbies</label>
              </Container>
              <Container>
                <TextArea
                  name="hobbies"
                  value={hobbies}
                  onChange={(e) => setHobbies(e.target.value)}
                  placeholder="Type something… (Up to 1000 words)"
                  maxLength="1000"
                  rows={4}
                />
              </Container>
            </Col>

            <Col className="col-lg-6 col-sm-12 mt-4">
              <Container className="text-left">
                <label className="label-text">Your Mobile Number</label>
              </Container>
              <Container>
                <TextInput
                  placeholder="Number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </Container>

              <Container className="text-left">
                <label className="label-text">Disclaimer</label>

                <div style={{fontSize: "0.65rem", color: "#9AA0A6"}}>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut ero labore et dolore. Lorem
                  ipsum dolor sit amet, consectetur adipisicing elit, sed do
                  eiusmod tempor incididunt ut ero labore et dolore, sit amet,
                  consectetur adipisicing elit, sed do eiusmod tempor incididunt
                  ut ero labore et dolor
                </div>

                <FormCheck
                  className="col-lg-6 color-green mt-2"
                  label="I agree"
                  id="agree"
                  onChange={() => setAgree(!agree)}></FormCheck>
              </Container>
            </Col>

            <Col className="col-lg-6 col-sm-12 mt-2">
              <Container className="text-left">
                <label className="label-text">A day in your life…</label>
              </Container>
              <Container>
                <TextArea
                  name="lifeOfDay"
                  value={lifeOfDay}
                  onChange={(e) => setLifeOfDay(e.target.value)}
                  placeholder="What is your example of a hectic weekday vs a regular weekend (Up to 3000 words)"
                  maxLength="3000"
                  rows={4}
                />
              </Container>
            </Col>
          </Row>
        </Container>
        <Button
          isSaving={isSaving}
          className="reg-page-btn mt-4 mb-5"
          onClick={(e) => onSubmit(e)}>
          CONTINUE
        </Button>
      </Container>
    </Container>
  );
}
