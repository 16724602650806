import React, {Component} from "react";
import $ from "jquery";
import Cropper from "./Crop/index";

class ImageSelector extends Component {
  state = {
    isSelected: false,
    src: "",
    showCropping: false,
    fileName: ""
  };

  strId = Math.floor(Math.random() * (999999 - 9999)) + 9999;
  isCropping = false;

  onSelect() {
    if (!this.isCropping) {
      $("#image-uploader-" + this.strId).click();
    }
  }

  onChange(e) {
    //const {name} = e.target;
    // this.setState({
    //   isSelected: true
    //   // [name]: URL.createObjectURL(e.target.files[0])
    // });
    const imageFile = e.target.files[0];

    if (imageFile) {
      this.setState({showCropping: true});
      this.toDataURL(URL.createObjectURL(imageFile), (dataUrl) => {
        this.isCropping = true;

        this.setState({
          src: dataUrl,
          fileName: imageFile.name
        });
      });
    }
  }

  toDataURL(src, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  }

  onCrop(url) {
    if (false !== url) {
      console.log(url);
      this.setState(
        {
          ["src-" + this.strId]: url,
          src: null,
          isSelected: true,
          showCropping: false
        },
        () => {
          this.isCropping = false;
        }
      );
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          this.props.onChange({
            name: this.props.name || "",
            value: blob,
            fileName: this.state.fileName
          });
        });
    } else {
      this.setState(
        {
          ["src-" + this.strId]: "",
          src: null,
          isSelected: false,
          showCropping: false
        },
        () => {
          this.isCropping = false;
        }
      );
    }
    this.clearFileInput();
  }

  clearFileInput = () => {
    var input = $("#image-uploader-" + this.strId);
    input.value = null;
  };

  render() {
    return (
      <div>
        <div
          className={"imgUploadContainer " + this.props.className}
          onClick={() => this.onSelect()}>
          {this.state.isSelected ? (
            <img
              src={this.state["src-" + this.strId]}
              className="image-selector-img middle-center"
              alt="+"
            />
          ) : (
            <div className="middle-center">+</div>
          )}
          <input
            type="file"
            id={"image-uploader-" + this.strId}
            className="d-none"
            accept="image/*"
            name={"src-" + this.strId}
            onChange={(e) => this.onChange(e)}
          />
        </div>
        {this.state.showCropping && (
          <Cropper src={this.state.src} onCrop={(p) => this.onCrop(p)} />
        )}
      </div>
    );
  }
}

export default ImageSelector;
