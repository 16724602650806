import React, {Component} from "react";
import {Container, Row, Col} from "react-bootstrap";
import humbleMatchIcon from "../Assets/humble_match_pink_icon.PNG";
export default class Footer extends Component {
  render() {
    return (
      <Container
        className="col-lg-12 col-sm-12 w-100  color-white"
        style={{fontSize: "0.8rem"}}>
        <Row className="p-5 bg-color-f-pink">
          <Container className="col-lg-2 col-sm-12">
            <Row className="font-weight-bold mb-4">CONTACT US</Row>
            {/* <Row>+91 98345 23654</Row> */}
            <Row>contact@humblematch.com</Row>
            <Row>Ask for help</Row>
          </Container>

          <Container className="col-lg-2 col-sm-12">
            <Row className="font-weight-bold mb-4">CUSTOMER SERVICE</Row>
            <Row>Contact Us</Row>
            <Row>Privacy Policy</Row>
            <Row>Terms & Conditions</Row>
            <Row>Copyrights</Row>
            <Row>Griviences</Row>
            <Row>FAQ</Row>
          </Container>

          <Container className="col-lg-2 col-sm-12">
            <Row className="font-weight-bold mb-4">INFORMATION</Row>
            <Row>About Humble Match</Row>
            <Row>Work With Us</Row>
            <Row>Press Enquiries</Row>
          </Container>

          <Container className="col-lg-4 col-md-4 col-sm-12">
            <Row className="font-weight-bold mb-4">Humble Match Newsletter</Row>
            <Row className="font-weight-normal">Keep in touch!</Row>
            <Row className="col-lg-12 px-0">
              <Container className="col-lg-6 col-md-12 p-0">
                <input
                  className="white-ft-input px-2 py-1 white-placeholder mt-2"
                  placeholder="Email Address"
                  style={{borderRadius: "0.3rem"}}></input>
              </Container>
              <Container className="col-lg-6 col-md-12 col-sm-12 px-2">
                <button
                  className="px-4 py-2 bg-color-white border-none mt-2"
                  style={{
                    borderRadius: "4px",
                    fontSize: "0.7rem"
                  }}>
                  SUBSCRIBE
                </button>
              </Container>
            </Row>
          </Container>
          <Container className="col-lg-2 col-sm-12 text-center">
            <img src={humbleMatchIcon} style={{height: "10rem"}} alt="icon" />
          </Container>
        </Row>

        <Row className="col-lg-12 py-2">
          <Col className="col-lg-12 col-sm-12 text-center label-text">
            © Humble Match 2020
          </Col>

          {/* <Col className="col-lg-6 col-sm-6 text-left label-text">
            Designed with love by Zeeshan
          </Col> */}
        </Row>
      </Container>
    );
  }
}
