import React, {Component} from "react";
import $ from "jquery";
import {BrowserView, MobileView} from "react-device-detect";
import {Container, Row, Button} from "react-bootstrap";

import welcomePage from "../Assets/welcome_video.mp4";
import greenPlus from "../Assets/green-plus.png";
import greenMinus from "../Assets/green-minus.svg";
import PaymentDetails from "./Form/PaymentDetails";
import FindMyMatch from "./Form/FindMyMatch";
import BehavioralInfo from "./BehavioralInfo";
import BehaviorInfoMobile from "./BehaviorInfoMobile";
import PlansMobile from "./PlansMobile";

export default class App extends Component {
  state = {
    tab: 1,
    showMinus: false,
    showMinusPromise: false
  };

  handleRegister() {
    window.location.href = "/register";
  }
  showMore(id, className) {
    if (id === "promise") {
      this.setState({
        showMinusPromise: true
      });
    } else {
      this.setState({
        showMinus: true
      });
    }
    $("#" + id).removeClass(className);
  }
  showLess(id, className) {
    if (id === "promise") {
      this.setState({
        showMinusPromise: false
      });
    } else {
      this.setState({
        showMinus: false
      });
    }

    $("#" + id).addClass(className);
  }
  render() {
    const button = (
      <Button
        className="bg-color-green reg-page-btn"
        onClick={() => this.handleRegister()}>
        REGISTER NOW
      </Button>
    );
    return (
      <div>
        {/* <img src={welcomePage} alt="" className="w-100 cursor" /> */}
        <video controls muted={true} autoPlay={true} fluid={false}  
                    className="homeVideo cursor">
          <source src={welcomePage} type="video/mp4" />
        </video>
        <Container className="mt-4 col-lg-10">
          <Row>
            <Container className="col-lg-6 col-sm-8 label-text">
              <Container className="col-lg-10 col-sm-12 label-text">
                <h4 className="color-pink" style={{fontSize: "1.2rem"}}>
                  <b>ABOUT</b>
                </h4>
                <p
                  className="mt-3 ellipsis"
                  id="aboutUs"
                  style={{
                    fontSize: "1rem",
                    lineHeight: 1.2,
                    textAlign: "justify",
                    textJustify: "inter-word"
                  }}>
                  The wholesomeness of new-age Indian Matchmaking. Minus the
                  clichés. The most thorough AI-powered Indian online matchmaker
                  you know. HumbleMatch brings back the rich traditional style
                  of Indian matchmaking, with an AI twist. Finding your soulmate
                  can’t just be about measuring the charts and ticking the usual
                  generic boxes. Most of the time, it is the little things that
                  make our hearts go: “Marry me!” It’s a thousand such little
                  (and big) things that HumbleMatch sifts through, before
                  suggesting you a potential match. HumbleMatch is the perfect
                  marriage of family-centric Indian match-making and
                  unparalleled AI precision. So if you’re eager to find your
                  perfect match without the usual hassles and in less time, sign
                  right up!.
                </p>
                {this.state.showMinus ? (
                  <i className="fa fa-minus home-plus-sign"  
                   onClick={() => this.showLess("aboutUs", "ellipsis")}
                  ></i>
                ) : (
                  <i className="fa fa-plus home-plus-sign" 
                    onClick={() => this.showMore("aboutUs", "ellipsis")}
                  />
                )}
              </Container>
            </Container>

            <Container className="col-lg-6 col-sm-8 label-text">
              <Container className="col-lg-10 col-sm-12 label-text">
                <h4 className="color-pink" style={{fontSize: "1.2rem"}}>
                  <b>OUR PROMISE</b>
                </h4>
                <p
                  className="mt-3 ellipsis"
                  id="promise"
                  style={{
                    fontSize: "1rem",
                    lineHeight: 1.2,
                    textAlign: "justify",
                    textJustify: "inter-word"
                  }}>
                  They say matches are made in heaven. The challenge is to find
                  it for yourself here on earth. HumbleMatch believes in finding
                  that perfect match for you with the right technique,
                  technology, and tenacity. With a powerful matchmaking AI
                  powered by behavioural analysts, psychologists, and lawmakers,
                  HumbleMatch brings soulmates closer, quicker! HumbleMatch is
                  also the only platform that identifies family members to be an
                  asset in the pursuit of finding you the perfect match. And
                  every opinion counts! Our AI understands this. And it
                  considers every member’s preferences staying true to the
                  Indian family-centric matchmaking process. HumbleMatch was
                  born as the answer to the question: “How can we tailor
                  traditional Indian matchmaking to the urban Indian”. The
                  answer is science, technology, and thoughtfulness! The answer
                  is HumbleMatch!
                </p>
                {this.state.showMinusPromise ? (
                   <i className="fa fa-minus home-plus-sign"  
                   onClick={() => this.showLess("promise", "ellipsis")}
                  ></i>
                ) : (
                  <i className="fa fa-plus home-plus-sign" 
                    onClick={() => this.showMore("promise", "ellipsis")}
                   />
                )}
              </Container>
            </Container>
          </Row>
        </Container>

        <Container className="p-0 mt-2 mb-4 text-center">{button}</Container>

        {/* <BrowserView>
          <BehavioralInfo />
        </BrowserView>
        <MobileView>
          <BehaviorInfoMobile />
        </MobileView>

        <Container className="my-5"></Container>

        <FindMyMatch />

        <Container className="my-5"></Container>

        <BrowserView>
          <PaymentDetails />
        </BrowserView>
        <MobileView>
          <PlansMobile />
        </MobileView> */}
      </div>
    );
  }
}
