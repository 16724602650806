import React, {Component} from "react";
import {Container, Row, Button} from "react-bootstrap";
import ThanksIcon from "../../Assets/thanksIcon.svg";
import {withRouter} from "react-router-dom";
class ThanksPage extends Component {
  state = {};

  handleRegister() {
    this.props.history.push("detailed_info");
  }

  render() {
    return (
      <Container className="text-center">
        <Container className="text-center">
          <h1 id="page-header" className="color-pink font-weight-normal">
            Welcome to the Family!
          </h1>
          {/* <h4
            id="page-sub-header"
            className="color-pink px-2 font-weight-normal mb-5">
            Congratulations! Your payment was successful.
          </h4> */}
        </Container>
        {/* <Container className="col-lg-5 my-4 px-0">
          <Row className="col-lg-12 p-0 col-sm-12 px-0 mx-0">
            {/* You have successfully subscribed to the Gold Plan.  */}
            {/* Complete the further steps of the registration to find the most
            compatible prospect. */}
          {/* </Row>
        </Container> */}
        {/* <Container className="px-0 mx-0 mt-4">
          <Button
            className="bg-color-green reg-page-btn"
            onClick={() => this.handleRegister()}>
            BUILD YOUR PROFILE
          </Button>
        </Container> */}
        <Container
          className="col-lg-4 col-md-6 col-sm-12 px-0"
          style={{height: "23.1rem"}}>
          <img alt="" src={ThanksIcon} style={{maxWidth: "100%"}} />
        </Container>
      </Container>
    );
  }
}

export default withRouter(ThanksPage);
