import {
  Form,
  Container,
  Row,
  Col,
  FormLabel,
  InputGroup
} from "react-bootstrap";
import React, {Component} from "react";
import Button from "../../templates/Button";
import Radio from "../../templates/Radio";
import {AUTH, CONNECT, RecaptchaVerifier} from "../../firebase/firebase";
import {setUserLoginData} from "../../Redux/actions/index";
import {connect} from "react-redux";
import * as CONSTANT from "../../Common/constants";
import {saveUserData} from "../../Model/users";
var captchaConfirm = null;
class BasicDetails extends Component {
  state = {
    registered: "Self",
    self: "Bride",
    gender: "Female",
    password: "",
    email: "",
    mobile: "",
    family_name: "",
    errors: [],
    isSaving: false,
    isShowAuthCode: false,
    auth_code: ""
  };

  constructor(props) {
    super(props);
    this.state = {
      registered: this.props.loginReducer.registered,
      self: this.props.loginReducer.self,
      gender: this.props.loginReducer.gender,
      email: this.props.loginReducer.email,
      mobile: this.props.loginReducer.mobile.slice(
        3,
        this.props.loginReducer.mobile.length
      ),
      family_name: this.props.loginReducer.familyName,
      errors: [],
      password: CONSTANT.NULL_STRING,
      isSaving: false,
      isShowAuthCode: false,
      auth_code: ""
    };

    this.strPhoneCode = "+91";
    this.recaptchaVerifier = null;
  }

  componentDidMount() {
    this.renderCaptch();
  }

  renderCaptch() {
    this.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
      size: "invisible",
      callback: (response) => {
        //.isCaptchaVerified = true;
        // this.verifyCode();
      }
      // "expired-callback": () => {
      //     this.isCaptchaVerified = false;
      //     alert("Solve Captcha Again!");
      // },
    });

    this.recaptchaVerifier.render();
  }

  verifyCode() {
    if (this.state.auth_code.length !== 6) {
      alert("Invalid Verification Code!");
      return;
    }
    this.setState({isSaving: true});
    captchaConfirm
      .confirm(this.state.auth_code)
      .then((response) => {
        // User signed in successfully.
        this.uid = response.user.uid;
        this.props.setLoginData({
          ...this.props.loginReducer,
          uid: response.user.uid
        });

        this.handleRegister();
        // ...
      })
      .catch(function (error) {
        // User couldn't sign in (bad verification code?)
        // ...
        alert("Wrong Verification code!");
        this.setState({isSaving: false});
      });
  }

  signIn(e) {
    if (this.validate()) {
      if (!this.state.isShowAuthCode) {
        this.sendVerificationCode();
      } else {
        this.verifyCode();
      }
    }
  }

  async sendVerificationCode() {
    this.setState({isSaving: true});
    let errors = [];
    const result = await AUTH.fetchSignInMethodsForEmail(this.state.email);

    const phoneVerification = await CONNECT.collection("consumer")
      .where("mobile", "==", this.strPhoneCode + this.state.mobile)
      .get();

    if (phoneVerification.docs > 0) {
      errors.push("mobile");
      this.setState({isMobileExists: true});
    }

    if (result.length > 0) {
      errors.push("email");
      this.setState({isSaving: false, isEmailExists: true, errors: ["email"]});
    } else {
      AUTH.signInWithPhoneNumber(
        this.strPhoneCode + this.state.mobile,
        this.recaptchaVerifier
      )
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          this.recaptchaVerifier.clear();
          captchaConfirm = confirmationResult;
          this.isCaptchaVerified = false;
          this.setState({isShowAuthCode: true, isShowResendButton: false});
          setTimeout(() => {
            document.getElementById("mobile").disabled = true;
            document.getElementById("auth_code").focus();
          }, 100);
          // this.resendInterval = setInterval(() => {
          //     this.setState({ otp_timeout: this.state.otp_timeout - 1 });
          //     if (this.state.otp_timeout === 0) {
          //         this.resendInterval && clearInterval(this.resendInterval);
          //         this.renderCaptch();
          //         this.setState({ isShowResendButton: true });
          //     }
          // }, 1000);
        })
        .catch(function (error) {
          alert(error);
          if (error.code === "auth/too-many-requests") {
            alert("Too Many Attempt, Please try after some time!");
          }
        })
        .finally(() => {
          this.setState({isSaving: false});
        });
    }
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]: value});
    if (
      name === "registered" &&
      ["Father", "Brother", "Mother", "Sister"].includes(value)
    ) {
      let gender = ["Father", "Brother"].includes(value) ? "Male" : "Female";
      this.setState({
        gender: gender
      });
    }
    if((this.state.registered === "Self" || value === "Self") && value === "Bride"){
      this.setState({
        gender: "Female"
      });
    }
    if((this.state.registered === "Self" || value === "Self") && value === "Groom") {
      this.setState({
        gender: "Male" 
      });
    }
  }

  handleNumber(e) {
    const {name, value} = e.target;
    /* mobile number validations */
    if ((!isNaN(value) && value[value.length - 1] !== ".") || value === "") {
      this.setState({[name]: value});
    }

    if (value.length === 10) {
      this.autoSentOtp(value);
    }
  }

  autoSentOtp(value) {
    AUTH.signInWithPhoneNumber(
      this.strPhoneCode + value,
      this.recaptchaVerifier
    )
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        this.recaptchaVerifier.clear();
        captchaConfirm = confirmationResult;
        this.isCaptchaVerified = false;
        this.setState({isShowAuthCode: true, isShowResendButton: false});
        setTimeout(() => {
          document.getElementById("mobile").disabled = true;
          document.getElementById("auth_code").focus();
        }, 100);
        // this.resendInterval = setInterval(() => {
        //     this.setState({ otp_timeout: this.state.otp_timeout - 1 });
        //     if (this.state.otp_timeout === 0) {
        //         this.resendInterval && clearInterval(this.resendInterval);
        //         this.renderCaptch();
        //         this.setState({ isShowResendButton: true });
        //     }
        // }, 1000);
      })
      .catch(function (error) {
        alert(error);
        if (error.code === "auth/too-many-requests") {
          alert("Too Many Attempt, Please try after some time!");
        }
      })
      .finally(() => {
        this.setState({isSaving: false});
      });
  }

  hasError(name) {
    return this.state.errors.includes(name) ? true : false;
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
  }

  validate() {
    this.setState({isSaving: true});

    let errors = [];
    // const regx = new RegExp("^(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
    // const isOk = regx.test(this.state.password);
    //if (!isOk) {
      if(this.state.password.length < 6){
      errors.push("password");
    }

    if (!this.validateEmail(this.state.email)) {
      errors.push("email");
    }

    if (this.state.mobile.length !== 10) {
      errors.push("mobile");
    }

    if (this.state.family_name.length < 3) {
      errors.push("family_name");
    }

    this.setState({errors, isSaving: false});

    return errors.length > 0 ? false : true;
  }

  async handleRegister(event) {
    const {
      email,
      mobile,
      password,
      family_name,
      registered,
      self,
      gender
    } = this.state;
    let registrationData = {
      email,
      mobile: this.strPhoneCode + mobile,
      password,
      familyName: family_name,
      registered,
      self,
      gender
    };

    const result = await AUTH.fetchSignInMethodsForEmail(this.state.email);
    if (result.length > 0) {
      this.renderCaptch();
      this.setState({isSaving: false, auth_code: ""});
      alert("Email already Exists!");
      this.autoSentOtp();
    } else {
      let uid = AUTH.currentUser.uid;
      this.props.setLoginData({
        loginStatus: CONSTANT.BOOL_TRUE,
        uid,
        userName: CONSTANT.NULL_STRING,
        familyName: family_name,
        mobile: this.strPhoneCode + mobile,
        self: self,
        gender: gender,
        email: email.trim(),
        registered: registered
      });

      saveUserData(registrationData, uid).then((response) => {
        AUTH.currentUser
          .updateEmail(this.state.email)
          .then((response) => {
            Promise.all([
              AUTH.currentUser.sendEmailVerification(),
              AUTH.currentUser.updatePassword(this.state.password)
            ]).finally(() => {
              this.setState({isSaving: false});
              this.props.handleRegister();
            });
          })
          .catch((error) => {
            this.setState({isSaving: false});
            console.log("error in create user", error);
          });
      });
    }
  }

  render() {
    return (
      <Container className=" col-sm-6 col-lg-4 col-md-6">
       <Form sm={3} role="form">
        <h1 id="page-header" className="color-pink px-2 font-weight-normal">
          Let's setup your family account!
        </h1>
        <div  className="bg-color-cream basic-reg-con-pad my-4">
           <Row className="justify-content-md-center">
            <Col className="text-center">
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter your email ID"
                className="border-none light-placeholder"
                value={this.state.email}
                onChange={(e) => this.handleChange(e)}
                isInvalid={this.hasError("email")}
              />
              <div
                className={this.hasError("email") ? "inline-error" : "d-none"}>
                {this.state.isEmailExists
                  ? "Email Already Exists!"
                  : "Please enter correct email"}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center my-3">
            <Col className="text-center">
              <InputGroup>
                <Form.Control  
                value={this.strPhoneCode} 
                className="border-none light-placeholder label-text col-sm-10 col-2 col-md-4 col-lg-3 mr-1" 
               />
                <Form.Control
                  name="mobile"
                  type="text"
                  id="mobile"
                  maxLength="10"
                  placeholder="Mobile Number"
                  className="border-none light-placeholder"
                  value={this.state.mobile}
                  onChange={(e) => this.handleNumber(e)}
                  isInvalid={this.hasError("mobile")}
                />
              </InputGroup>
              <div
                className={this.hasError("mobile") ? "inline-error" : "d-none"}>
                {this.state.isMobileExists
                  ? "Mobile number already exists"
                  : "Please enter valid mobile mobile"}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center my-3">
            <Col className="text-center">
              <Form.Control
                name="auth_code"
                type="text"
                maxLength="6"
                id="auth_code"
                placeholder="Enter Verification Code"
                className="border-none light-placeholder"
                value={this.state.auth_code}
                onChange={(e) => this.handleNumber(e)}
                isInvalid={this.hasError("auth_code")}
                disabled={this.state.isShowAuthCode ? "" : "disabled"}
              />
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col className="text-center">
              <Form.Control
                type="password"
                name="password"
                maxLength="20"
                value={this.state.password}
                placeholder="Create Password"
                className="border-none light-placeholder"
                onChange={(e) => this.handleChange(e)}
                isInvalid={this.hasError("password")}
              />
              <div
                className={
                  this.hasError("password") ? "inline-error" : "d-none"
                }>
                Password must be at least one upper, lower case letter and one
                number
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-left">
            <FormLabel className="label-text px-3 mt-2">
              Choose a family account name
            </FormLabel>
          </Row>
          <Row className="justify-content-md-center">
            <Col className="text-center">
              <Form.Control
                type="text"
                name="family_name"
                placeholder="Example - Patil Pariwar / The Sharmas"
                className="border-none light-placeholder"
                onChange={(e) => this.handleChange(e)}
                value={this.state.family_name}
                isInvalid={this.hasError("family_name")}
              />
              <div
                className={
                  this.hasError("family_name") ? "inline-error" : "d-none"
                }>
                Please Enter Family Name
              </div>
            </Col>
          </Row>
          <Container className="px-3 mt-3">
            <Row className="justify-content-md-left">
              <FormLabel>You are</FormLabel>
            </Row>
            <Row className="justify-content-md-left">
              <Radio
                name="registered"
                value={this.state.registered}
                labels={[
                  "Self",
                  "Mother",
                  "Father",
                  "Brother",
                  "Sister",
                  "Relative",
                  "Friend"
                ]}
                onChange={(e) => this.handleChange(e)}
              />
            </Row>
            <Row className="justify-content-md-left mt-2">
              <FormLabel>Profile For</FormLabel>
            </Row>
            <Row className="justify-content-md-left">
              <Radio
                name="self"
                value={this.state.self}
                labels={["Bride", "Groom"]}
                onChange={(e) => this.handleChange(e)}
              />
            </Row>
            <Row className="justify-content-md-left">
              <FormLabel>Gender</FormLabel>
            </Row>
            <Row className="justify-content-md-left">
              <Radio
                name="gender"
                value={this.state.gender}
                labels={["Female", "Male"]}
                onChange={(e) => this.handleChange(e)}
              />
            </Row>
          </Container>

          <Row className="justify-content-md-center text-center mt-3">
            <Container>
              <Button
                className="bg-color-green reg-page-btn"
                onClick={(e) => this.signIn(e)}
                isSaving={this.state.isSaving}>
                REGISTER
              </Button>
            </Container>
          </Row>
        <div id="recaptcha-container"></div>
        </div>
      </Form>
      </Container>   
    );
  }
}

const mapStateToProps = (reduxStore) => {
  return {
    ...reduxStore
  };
}; //end function

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginData: (arrLoginData) => {
      dispatch(setUserLoginData(arrLoginData));
    }
  };
}; //end function

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);
