import React, {useState} from "react";
import BasicDetails from "./BasicDetails";
import ThanksPage from "./ThanksPage";
import BottomTab from "../../templates/BottomTab";
import {useSelector} from "react-redux";
import {Container} from "react-bootstrap";

function handleRegister(value, setTab) {
  setTab(value + 1);
}

function renderComponents(tab, setTab, uid) {
  switch (tab) {
    case 1:
      return (
        <BasicDetails handleRegister={(e) => handleRegister(tab, setTab)} />
      );
    // case 2:
    //   return (
    //     <PaymentDetails
    //       handleRegister={(e) => handleRegister(tab, setTab)}
    //       uid={uid}
    //     />
    //   );
    // case 3:
    //   return <PaymentPage handleRegister={(e) => this.handleRegister(e)} />;
    case 2:
      return <ThanksPage handleRegister={(e) => handleRegister(tab, setTab)} />;
    default:
      return null;
  }
}

export default function Registration() {
  const [tab, setTab] = useState(1);
  const uid = useSelector((redux) => redux.loginReducer.uid);

  /* commented for now */
  // const history = useHistory();

  // useEffect(() => {
  //   if (uid !== "") {
  //     history.push("home");
  //   }
  // }, [history, uid]);

  return (
    <div>
      <div className="pt-4 text-center">
        {renderComponents(tab, setTab, uid)}
      </div>
      <Container className="col-lg-3 col-md-4 col-sm-10 mb-4">
        <BottomTab
          clickedTab={tab}
          tabs={2}
          onClick={(p) => setTab(p)}
          disabled={true}
        />
      </Container>
    </div>
  );
}
