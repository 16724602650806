import {Component} from "react";
import {Container, Row} from "react-bootstrap";
import Dropdown from "../../templates/Dropdown";
import TextInput from "../../templates/TextInput";
import Button from "../../templates/Button";
import ImageSelector from "../../templates/ImageSelector";
//import CreateProfile from "./CreateProfile";
import {YESNO, MARITAL_STATUS} from "../../Common/constants";
import {updateUserData} from "../../Model/users";
import {setUserRelatives} from "../../Model/relatives";
import {connect} from "react-redux";
import {
  getDbObject,
  getRandomString,
  getStorageObject
} from "../../Common/commonFunction";
import {setUserLoginData} from "../../Redux/actions";
import {uploadFileWithName} from "../../Model/uploadFile";
class DetailedInfo extends Component {
  arrChangedValues = [];

  state = {
    arrSelf: ["Bride", "Groom"],
    arrRelatives: ["mother", "father", "sister", "brother"],
    errors: [],
    isSaving: false,
    disability: "",
    maritalStatus: "",
    age: 21,
    fname: "",
    lname: "",
    self: this.props.self || "Bride"
  };

  validate() {
    let errors = [];
    let isAllowed = true;

    if (!this.state.fname) {
      errors.push("fname");
      isAllowed = false;
    }

    if (!this.state.lname) {
      errors.push("lname");
      isAllowed = false;
    }

    if (!this.state.age || isNaN(this.state.age) || this.state.age === "0") {
      errors.push("age");
      isAllowed = false;
    }

    if (!this.state.occupation) {
      errors.push("occupation");
      isAllowed = false;
    }

    if (!this.state.disability) {
      errors.push("disability");
      isAllowed = false;
    }

    if (!this.state.maritalStatus) {
      errors.push("maritalStatus");
      isAllowed = false;
    }

    this.arrChangedValues.forEach((relative) => {
      if (!this.state[relative + "_fname"]) {
        errors.push(relative + "_fname");
        isAllowed = false;
      }

      if (!this.state[relative + "_lname"]) {
        errors.push(relative + "_lname");
        isAllowed = false;
      }

      if (
        !this.state[relative + "_age"] ||
        isNaN(this.state[relative + "_age"]) ||
        this.state[relative + "_age"] === "0"
      ) {
        errors.push(relative + "_age");
        isAllowed = false;
      }

      if (!this.state[relative + "_occupation"]) {
        errors.push(relative + "_occupation");
        isAllowed = false;
      }

      if (!this.state[relative + "_disability"]) {
        errors.push(relative + "_disability");
        isAllowed = false;
      }

      if (!this.state[relative + "_maritalStatus"]) {
        errors.push(relative + "_maritalStatus");
        isAllowed = false;
      }
    });

    this.setState({errors});

    return isAllowed;
  }

  onSubmit() {
    this.setState({isSaving: true});
    if (this.validate()) {
      let userData = {
        self: this.state.self,
        fname: this.state.fname,
        lname: this.state.lname,
        age: this.state.age,
        maritalStatus: this.state.maritalStatus,
        disability: this.state.disability,
        familyPhotos: []
      };

      this.props.setLoginData({...this.props.userData, ...userData});

      const db = getDbObject();

      this.arrChangedValues.forEach((relative) => {
        let arrData = {
          relation: relative,
          fname: this.state[relative + "_fname"],
          lname: this.state[relative + "_lname"],
          age: this.state[relative + "_age"] * 1,
          maritalStatus: this.state[relative + "_maritalStatus"],
          disability: this.state[relative + "_disability"],
          uid: this.props.uid
        };

        setUserRelatives(arrData, true, db);
      });

      let promise = [];
      [...Array(10)].map((e, date) => {
        let image = this.state["image" + (date + 1)];
        if (image) {
          promise.push(
            uploadFileWithName(
              getStorageObject(),
              "family_photos",
              this.props.uid + "/" + getRandomString() + "_" + image.fileName,
              image.image
            )
          );
        }
        return null;
      });

      Promise.all(promise)
        .then((response) => {
          userData.familyPhotos = response;
          updateUserData(userData, this.props.uid, true, db);
          db.executeAllBatches()
            .then(() => {
              this.props.onSubmit();
            })
            .catch((error) => {
              alert("something went wrong!");
              this.setState({isSaving: false});
            });
        })
        .catch((error) => {
          alert("unable to upload images!");
          this.setState({isSaving: false});
        });
    } else {
      this.setState({isSaving: false});
    }
  }

  handleChange(e, isRelative = true) {
    const {name, value} = e.target;

    /* used for validation purpose */
    const temp = name.split("_");
    if (isRelative && !this.arrChangedValues.includes(temp[0])) {
      this.arrChangedValues.push(temp[0]);
    }

    this.setState({[name]: value});
  }

  hasError(name) {
    return this.state.errors.includes(name) ? true : false;
  }

  handleSelectImage(e) {
    this.setState({
      [e.name]: {
        fileName: e.fileName,
        image: e.value
      }
    });
  }

  render() {
    return (
      <Container className="w-100 col-lg-12">
        <Container className="col-lg-10 text-center pt-4">
          <h1 className="color-pink font-weight-normal">
            Complete your family tree
          </h1>

          <Container className="col-lg-12 bg-color-cream py-4 px-lg-5 px-sm-3 px-md-3 mt-4">
            {/* <div className="text-left">
              <label className="label-text">Bride / Groom</label>
            </div> */}

            <Row className="w-100 col-lg-12 px-0 m-0">
              <Row className="col-lg-2 col-sm-12 px-0 m-0 mt-2">
                <label className="label-text">{this.state.self}</label>
              </Row>

              <Row className="col-lg-3 col-sm-12 px-0 m-0 mt-2">
                <TextInput
                  name="fname"
                  type="text"
                  maxLength="50"
                  placeholder="First Name"
                  value={this.state.fname}
                  onChange={(e) => this.handleChange(e, false)}
                  style={{width: "45%", marginRight: "5%"}}
                  isInvalid={this.hasError("fname")}
                />
                <TextInput
                  name="lname"
                  type="text"
                  maxLength="50"
                  placeholder="Last Name"
                  value={this.state.lname}
                  onChange={(e) => this.handleChange(e, false)}
                  style={{width: "45%", marginRight: "5%"}}
                  isInvalid={this.hasError("lname")}
                />
              </Row>

              <Row className="col-lg-3 col-sm-12 px-0 m-0  mt-2">
                <TextInput
                  name="age"
                  type="number"
                  min={0}
                  placeholder="Age"
                  style={{width: "45%", marginRight: "5%"}}
                  value={this.state.age}
                  onChange={(e) => this.handleChange(e, false)}
                  isInvalid={this.hasError("age")}
                />
                <TextInput
                  name="occupation"
                  type="text"
                  maxLength="50"
                  placeholder="Occupation"
                  style={{width: "45%", marginRight: "5%"}}
                  value={this.state.occupation}
                  onChange={(e) => this.handleChange(e, false)}
                  isInvalid={this.hasError("occupation")}
                />
              </Row>

              <Row className="col-lg-4 col-sm-12 px-0 m-0  mt-2">
                <Dropdown
                  name="maritalStatus"
                  style={{width: "45%", marginRight: "5%"}}
                  data={MARITAL_STATUS}
                  title="Marital Status"
                  value={this.state.maritalStatus}
                  onChange={(e) => this.handleChange(e, false)}
                  isInvalid={this.hasError("maritalStatus")}
                />

                <Dropdown
                  name="disability"
                  style={{width: "45%", marginRight: "5%"}}
                  data={YESNO}
                  title="Disability"
                  onChange={(e) => this.handleChange(e, false)}
                  value={this.state.disability}
                  isInvalid={this.hasError("disability")}
                />
              </Row>
            </Row>

            {this.state.arrRelatives.map((relative) => {
              return (
                <>
                  <Row className="w-100 col-lg-12 px-0 m-0">
                    <Row className="col-lg-2 col-sm-12 px-0 m-0 mt-2">
                      <label className="label-text mt-3 text-capitalize">
                        {relative}
                      </label>
                    </Row>

                    <Row className="col-lg-3 col-sm-12 px-0 m-0 mt-2">
                      <TextInput
                        name={relative + "_fname"}
                        type="text"
                        maxLength="50"
                        placeholder="First Name"
                        style={{width: "45%", marginRight: "5%"}}
                        onChange={(e) => this.handleChange(e)}
                        value={this.state[relative + "_fname"]}
                        isInvalid={this.hasError(relative + "_fname")}
                      />
                      <TextInput
                        name={relative + "_lname"}
                        type="text"
                        maxLength="50"
                        placeholder="Last Name"
                        style={{width: "45%", marginRight: "5%"}}
                        onChange={(e) => this.handleChange(e)}
                        value={this.state[relative + "_lname"]}
                        isInvalid={this.hasError(relative + "_lname")}
                      />
                    </Row>

                    <Row className="col-lg-3 col-sm-12 px-0 m-0  mt-2">
                      <TextInput
                        name={relative + "_age"}
                        type="text"
                        maxLength="50"
                        placeholder="Age"
                        style={{width: "45%", marginRight: "5%"}}
                        onChange={(e) => this.handleChange(e)}
                        value={this.state[relative + "_age"]}
                        isInvalid={this.hasError(relative + "_age")}
                      />
                      <TextInput
                        name={relative + "_occupation"}
                        type="text"
                        maxLength="50"
                        placeholder="Occupation"
                        style={{width: "45%", marginRight: "5%"}}
                        onChange={(e) => this.handleChange(e)}
                        value={this.state[relative + "_occupation"]}
                        isInvalid={this.hasError(relative + "_occupation")}
                      />
                    </Row>

                    <Row className="col-lg-4 col-sm-12 px-0 m-0  mt-2">
                      <Dropdown
                        name={relative + "_maritalStatus"}
                        style={{width: "45%", marginRight: "5%"}}
                        data={MARITAL_STATUS}
                        title="Marital Status"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state[relative + "_maritalStatus"]}
                        isInvalid={this.hasError(relative + "_maritalStatus")}
                      />

                      <Dropdown
                        name={relative + "_disability"}
                        style={{width: "45%", marginRight: "5%"}}
                        data={YESNO}
                        title="Disability"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state[relative + "_disability"]}
                        isInvalid={this.hasError(relative + "_disability")}
                      />
                    </Row>
                  </Row>
                </>
              );
            })}

            <div className="text-left mt-2">
              <label className="label-text">Add Family Photos</label>
              <Row className="d-flex justify-content-around">
                <ImageSelector
                  className="mt-2"
                  name="image1"
                  onChange={(e) => this.handleSelectImage(e)}
                />

                <ImageSelector
                  className="mt-2"
                  name="image2"
                  onChange={(e) => this.handleSelectImage(e)}
                />

                <ImageSelector
                  className="mt-2"
                  name="image2"
                  onChange={(e) => this.handleSelectImage(e)}
                />

                <ImageSelector
                  className="mt-2"
                  name="image2"
                  onChange={(e) => this.handleSelectImage(e)}
                />

                <ImageSelector
                  className="mt-2"
                  name="image2"
                  onChange={(e) => this.handleSelectImage(e)}
                />

                <ImageSelector
                  className="mt-2"
                  name="image2"
                  onChange={(e) => this.handleSelectImage(e)}
                />

                <ImageSelector
                  className="mt-2"
                  name="image2"
                  onChange={(e) => this.handleSelectImage(e)}
                />

                <ImageSelector
                  className="mt-2"
                  name="image2"
                  onChange={(e) => this.handleSelectImage(e)}
                />

                <ImageSelector
                  className="mt-2"
                  name="image2"
                  onChange={(e) => this.handleSelectImage(e)}
                />

                <ImageSelector
                  className="mt-2"
                  name="image2"
                  onChange={(e) => this.handleSelectImage(e)}
                />
              </Row>
              <Row>
                <label className="mt-2 label-text">
                  You can add unto 10 family photos
                </label>
              </Row>
              <Row className="justify-content-around">
                <Button
                  className="reg-page-btn my-3"
                  onClick={() => this.onSubmit()}>
                  CONTINUE
                </Button>
              </Row>
              {/* <Row className="mt-2 justify-content-around">
                <h5 className="color-bright-pink text-decoration">
                  <u>Skip to my behavioral test</u>
                </h5>
              </Row> */}
            </div>
          </Container>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (reduxStore) => {
  return {
    uid: reduxStore.loginReducer.uid,
    self: reduxStore.loginReducer.self,
    userData: reduxStore.loginReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginData: (arrLoginData) => {
      dispatch(setUserLoginData(arrLoginData));
    }
  };
}; //end function

export default connect(mapStateToProps, mapDispatchToProps)(DetailedInfo);
