import React from "react";
import {Button, Spinner} from "react-bootstrap";

function HumbleButton(props) {
  const className = props.className || "";
  const style = props.style || {};
  const children = props.children || null;
  const isSaving = props.isSaving || false;
  const onClick = () => {
    if (!isSaving) {
      props.onClick && props.onClick();
    }
  };

  return (
    <Button
      className={"bg-color-green pry-btn " + className}
      onClick={(e) => onClick(e)}
      style={style}>
      {!isSaving ? (
        <span>{children}</span>
      ) : (
        <span>
          {props.clickText || <Spinner size="sm" animation="border"></Spinner>}
        </span>
      )}
    </Button>
  );
}

export default React.memo(HumbleButton);
