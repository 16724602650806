import React from "react";
import {Navbar, Nav} from "react-bootstrap";
import logo from "../Assets/humble_match.svg";
import {useSelector, useDispatch} from "react-redux";
import {AUTH} from "../firebase/firebase";
import {useHistory} from "react-router-dom";

function Logout(dispatch, history) {
  dispatch({type: "user_logout"});
  history.push("home");
  AUTH.signOut();
}

export default function NavBar() {
  const loginStatus = useSelector((state) => state.loginReducer.loginStatus);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Navbar className="bg-color-pink px-5 p-0" variant="dark">
      <Navbar.Brand href="/home">
        <img style={{height: "3rem"}} src={logo} alt="logo" />
      </Navbar.Brand>
      <Nav className="ml-auto home-nav">
        {loginStatus ? (
          <Nav.Link
            className="ml-4 color-green"
            onClick={() => Logout(dispatch, history)}>
            LOGOUT
          </Nav.Link>
        ) : (
          <>
            <Nav.Link href="/login" className="color-green">
              LOGIN
            </Nav.Link>
            <Nav.Link href="/register" className="ml-4 color-green">
              REGISTER
            </Nav.Link>
          </>
        )}
      </Nav>
    </Navbar>
  );
}
