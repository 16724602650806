import {LOGIN_SUCCESS_DATA, USER_LOGOUT} from "../actions/types";

import * as CONSTANT from "../../Common/constants";

const INITIAL_STATE = {
  loginStatus: CONSTANT.BOOL_FALSE,
  uid: CONSTANT.NULL_STRING,
  userName: CONSTANT.NULL_STRING,
  familyName: CONSTANT.NULL_STRING,
  mobile: CONSTANT.NULL_STRING,
  self: CONSTANT.BRIDE,
  gender: CONSTANT.FEMALE,
  email: CONSTANT.NULL_STRING,
  registered: CONSTANT.SELF,
  fName: CONSTANT.NULL_STRING,
  lName: CONSTANT.NULL_STRING,
  maritalStatus: CONSTANT.NULL_STRING
};

export default function loginReducer(arrOldState = INITIAL_STATE, arrAction) {
  let arrNewState = {};

  switch (arrAction.type) {
    case LOGIN_SUCCESS_DATA:
      if (arrAction.payload) {
        const {
          loginStatus,
          uid,
          userName,
          familyName,
          mobile,
          self,
          gender,
          email,
          registered,
          fname,
          lname,
          maritalStatus
        } = arrAction.payload;
        arrNewState = {
          ...arrOldState,
          loginStatus: loginStatus || arrOldState.loginStatus,
          uid: uid || arrOldState.uid,
          userName: userName || arrOldState.userName,
          familyName: familyName || arrOldState.familyName,
          mobile: mobile || arrOldState.mobile,
          self: self || arrOldState.self,
          gender: gender || arrOldState.gender,
          email: email || arrOldState.email,
          registered: registered || arrOldState.registered,
          fname: fname || arrOldState.fname,
          lname: lname || arrOldState.lname,
          maritalStatus: maritalStatus || arrOldState.maritalStatus
        };
      } else {
        arrNewState = {
          ...arrOldState
        };
      }
      break;
    case USER_LOGOUT:
      arrNewState = INITIAL_STATE;
      break;
    default:
      arrNewState = arrOldState;
      break;
  } //end switch

  return arrNewState;
} //end function
