import React from "react";
import {Dropdown} from "react-bootstrap";

function DropDown(props) {
  const onChange = (value) => {
    try {
      props.onChange({
        target: {
          name: props.name || "",
          value: value
        }
      });
    } catch (error) {}
  };

  return (
    <Dropdown
      onSelect={(eventKey) => onChange(eventKey)}
      className={
        "text-left dropdown-container " + (props.isInvalid ? "border-red" : "")
      }
      style={props.style || {}}>
      <Dropdown.Toggle
        className={
          "w-100 text-left border-none light-placeholder mid-height-input bg-color-white label-text ellipsis " +
          props.className
        }
        style={props.titleStyle || {}}>
        {props.value || props.title || ""}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {props.data?.map((value) => {
          return (
            <Dropdown.Item className="ellipsis" eventKey={value}>
              {value}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default React.memo(DropDown);
