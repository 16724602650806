import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/messaging";
import "firebase/functions";

/* ********************************************************************************************************************************************* */
//NOTE:- ENV variables are set at the server run time.i.e at npm start. So when ever the ENV variables are changed, you need to restart the server
/* ********************************************************************************************************************************************* */

// const config = {
//   apiKey: "AIzaSyB-HQwNp5WYB3Sgwh1yxpv4vgKUAaJkVzc",
//   authDomain: "humblematch-preprod.firebaseapp.com",
//   projectId: "humblematch-preprod",
//   storageBucket: "humblematch-preprod.appspot.com",
//   messagingSenderId: "1039691755516",
//   appId: "1:1039691755516:web:2a1e4be85ce60afbdccc79",
//   measurementId: "G-4STDV8M7P7"
// };

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID
};

const humbleMatch = firebase.initializeApp(config);

// if (window.location.hostname === "localhost") {
//   humbleMatch.functions().useFunctionsEmulator("http://localhost:5001");
//   humbleMatch.auth().useEmulator("http://localhost:9099");
//   humbleMatch.firestore().settings({
//     host: "localhost:8080",
//     ssl: false
//   });
// }

// firebase.firestore().enablePersistence({synchronizeTabs:true}).then().catch( error => {
// 	vendLog.error(this, 'Error in Enabling Persistence', error);
// });
console.log(
  "humbleMatch.messaging.isSupported()",
  firebase.messaging.isSupported()
);
if (firebase.messaging.isSupported()) {
  humbleMatch.messaging().onMessage((payload) => {
    const notificationTitle = "A New Activity Happend.!!";

    console.log("payload : ", payload);
    navigator.serviceWorker
      .getRegistration()
      .then((reg) => {
        return new Notification(notificationTitle);
      })
      .catch((error) => {
        console.log("firebase.js", error);
      });
  });
}

const CONNECT = humbleMatch.firestore();
const AUTH = humbleMatch.auth();
const EmailAuthProvider = humbleMatch.auth.EmailAuthProvider;
const STORAGE = humbleMatch.storage();
const FIELDVALUE = firebase.firestore.FieldValue;
const FIELDPATH = firebase.firestore.FIELDPATH;
const RecaptchaVerifier = firebase.auth.RecaptchaVerifier;
const MESSANGING = firebase.messaging.isSupported()
  ? humbleMatch.messaging()
  : null;
const BATCH = humbleMatch.firestore().batch();

export {
  CONNECT,
  AUTH,
  STORAGE,
  FIELDVALUE,
  FIELDPATH,
  MESSANGING,
  BATCH,
  RecaptchaVerifier,
  EmailAuthProvider
};
