import DBFunctions from "./DBFunctions";
import {collectionName} from "../Common/constants";
const objDBFunctions = new DBFunctions();

export const saveUserData = (arrData, docId = "") =>
  new Promise((resolve, reject) => {
    let query =
      docId !== ""
        ? objDBFunctions.addDocumentWithPredefinedId(
            {[collectionName.CONSUMER]: ""},
            docId,
            arrData,
            true
          )
        : objDBFunctions.addDocument(
            {[collectionName.CONSUMER]: ""},
            arrData,
            false
          );

    query
      .then((arrStatus) => {
        resolve(arrStatus);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateUserData = async (
  arrData,
  docId,
  isBatch = false,
  dbObject = null
) => {
  try {
    if (null === dbObject) {
      dbObject = objDBFunctions;
    }

    const response = await dbObject.updateDocument(
      {[collectionName.CONSUMER]: ""},
      docId,
      arrData,
      isBatch
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const setUserRelatives = async (
  uid,
  arrData,
  docId,
  isBatch = false,
  dbObject
) => {
  try {
    if (null === dbObject) {
      dbObject = objDBFunctions;
    }

    const response = await dbObject.addDocumentWithPredefinedId(
      {[collectionName.CONSUMER]: uid, relatives: ""},
      docId,
      arrData,
      isBatch
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserData = async (docId) => {
  try {
    const response = await objDBFunctions.getDocumentById(
      {[collectionName.CONSUMER]: ""},
      docId
    );
    return response;
  } catch (error) {
    return {};
  }
};

export const getUserDataByCondition = async (condition) => {
  try {
    const response = await objDBFunctions.getDocuments(
      {[collectionName.CONSUMER]: ""},
      condition
    );
    return response;
  } catch (error) {
    console.log(error);
    return {};
  }
};
