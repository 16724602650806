import React from "react";
import {Form} from "react-bootstrap";

function TextArea(props) {
  return (
    <Form.Control
      as="textarea"
      name={props.name || ""}
      rows={props.rows || 3}
      value={props.value || ""}
      onChange={(e) => props.onChange(e) || ""}
      className={"light-placeholder " + props.className}
      placeholder={props.placeholder || "Type something"}
      maxLength={props.maxLength || "2000"}
      style={{fontSize: "0.9rem"}}
    />
  );
}

export default React.memo(TextArea);
